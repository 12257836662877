import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ExportTask, ExportTaskConfig, PictureConfig, Reference} from '../../common';
import {CarModelSearchRequest} from '../../../carmodel-search/carmodel-search-request';
import {ExportTaskCreationService} from './exporttask-creation.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-exporttask-creation',
  templateUrl: './creation.component.html',
  styleUrls: ['./creation.component.scss']
})
export class CreationComponent implements OnInit {

  availableSchedulings = ExportTaskConfig.availableSchedulings;
  availablePictureFormats = ['JPG', 'PNG'];
  availablePictureTypes = ['MAIN', 'ALL'];
  availableRanks = CarModelSearchRequest.availableRanks;

  cmpformGroup: FormGroup;
  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public executedOnce: boolean,
    private formBuilder: FormBuilder,
    private exportTaskCreationService: ExportTaskCreationService
  ) { }

  ngOnInit() {
    const required256 = Validators.compose([Validators.required, Validators.maxLength(256)]);
    const optional5 = Validators.maxLength(5);
    this.cmpformGroup = this.formBuilder.group({
      name: [null, required256],
      partnerId: [null, Validators.required],

      scheduling: [(this.executedOnce ? ExportTaskConfig.onceScheduling : this.availableSchedulings[0]), Validators.required],
      diffExport: [false],
      contactEmailAddress: ['', Validators.compose([Validators.email, Validators.maxLength(128)])],

      pictureSize: ['I2', Validators.required],
      pictureFormat: [this.availablePictureFormats[0], Validators.required],
      pictureType: [this.availablePictureTypes[0], Validators.required],

      zones: [null],
      carMaker: [null],
      vehicleClasses: [null],
      acrissCode: ['', optional5],
      isVehicleClasses: [false],
      modelRanks: [null],
      createdOn: [null],
      lastModifiedOn: [null],
      createdAfter: [null],
      lastModifiedAfter: [null]
    });
  }

  /** @return All data stored in the form */
  private formData() {
    const f = this.cmpformGroup.controls;
    const partner = new Reference(f.partnerId.value);
    const pictureConfig = new PictureConfig(f.pictureSize.value, f.pictureFormat.value, f.pictureType.value);
    const config = new ExportTaskConfig(true, f.scheduling.value, f.diffExport.value, pictureConfig, f.contactEmailAddress.value);
    const carModelSearchRequest = new CarModelSearchRequest(null, null, null,
      f.createdAfter.value, f.lastModifiedAfter.value, f.createdOn.value,
      f.lastModifiedOn.value, f.carMaker.value, CarModelSearchRequest.normalizeMultiSelectValue(f.zones.value), CarModelSearchRequest.computeAcrisses(f.isVehicleClasses.value, f.vehicleClasses.value, f.acrissCode.value),
      CarModelSearchRequest.normalizeMultiSelectValue(f.modelRanks.value), false, false);
    return new ExportTask(null, null, f.name.value, partner, config, carModelSearchRequest, null);
  }

  /** Executed when the user confirms the action */
  onSubmit() {
    this.actionState.register(this.exportTaskCreationService.create(this.formData()));
  }

}
