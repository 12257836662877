<div>
  <div class="table-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
    <p *ngIf="items$ | async as items; else loadingP" i18n>{items.totalElements || 0, plural,
      =0 {no partners} =1 {1 partner} other { {{items.totalElements}} partners} } found</p>
    <ng-template #loadingP>
      <p fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
        <mat-spinner diameter="20"></mat-spinner><span i18n>Loading partners...</span>
      </p>
    </ng-template>
  </div>
  <div>
    <table mat-table [dataSource]="(items$ | async)?.content">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef i18n>Name</th>
        <td mat-cell *matCellDef="let partner"><strong>{{partner.name}}</strong></td>
      </ng-container>
      <ng-container matColumnDef="carModelType">
        <th mat-header-cell *matHeaderCellDef i18n>Use type</th>
        <td mat-cell *matCellDef="let partner">
          <ng-container *ngIf="partner.carModelType === 'ALL'; else allCarModelType" i18n>GDS</ng-container>
          <ng-template #allCarModelType i18n>RESAWEB</ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let partner">
          <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-button color="primary" (click)="openUpdateDialog(partner)" i18n>Edit</button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    </table>
  </div>
</div>
