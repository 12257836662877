<div id="user-password-box" class="open-page" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title i18n>Password modification</mat-card-title>
    <mat-card-subtitle i18n>
      <span *ngIf="actionState.initial">Please, fill in your new password.</span>
      <strong *ngIf="actionState.success" class="success">Success</strong>
      <strong *ngIf="actionState.error" class="warn">Error</strong>
    </mat-card-subtitle>
    <mat-card-content fxLayout="column">
      <form *ngIf="actionState.initial" [formGroup]="cmpformGroup">
        <p>
          <mat-form-field>
            <mat-label i18n>Password</mat-label>
            <input matInput formControlName="password" required minlength="8" maxlength="30" type="password"/>
            <mat-hint align="start" i18n>Contains from 8 to 30 characters. Letters, digits and some special characters
              are allowed: _-!:;,@[]()=#~%
            </mat-hint>
            <mat-error *ngIf="f.password.errors" i18n>Invalid password. Format is: 8 to 30 characters, with letters,
              digits and some special characters: _-!:;,@[]()=#~%
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <mat-label i18n>Password confirmation</mat-label>
            <input matInput formControlName="passwordConfirmation" minlength="8" maxlength="30" required type="password" />
            <mat-error *ngIf="noMatchForPasswords" i18n>The two passwords must match.</mat-error>
          </mat-form-field>
        </p>
      </form>
      <p *ngIf="actionState.success" i18n>Your password was modified successfully.</p>
      <p *ngIf="actionState.error" class="warn" i18n>Your password could not be modified.</p>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
        <button *ngIf="actionState.initial" mat-raised-button color="primary" [disabled]="noMatchForPasswords || actionState.loading" (click)="onSubmit()" i18n>Modify password</button>
        <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
        <button mat-button routerLink="/user/self/login" i18n>Back to login page</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
