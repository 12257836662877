import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

/** Intercepts any HTTP 401 error and then reloads the page so that the user can login again */
@Injectable()
export class HttpError401Interceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(httpResponse => {
      return throwError(this.handleHttp401Error(httpResponse));
    }));
  }

  handleHttp401Error(httpResponse: HttpErrorResponse) {
    if (!this.isAuthenticationUrl(httpResponse.error.path) && httpResponse.status === 401) {
      this.authenticationService.logout();
      this.reloadPage();
    }
    return httpResponse;
  }

  /** @return Whether the passed URL value is the authentication WS one. */
  private isAuthenticationUrl(value: string) {
    return value === '/api/open/users/authentication';
  }

  /** Reloads the current page */
  private reloadPage() {
    location.reload(true);
  }
}
