import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

import {Partner} from '../../common';
import {PartnerRetrievalService} from './partner-retrieval.service';

import {Page} from '../../../common';

import {PartnerUpdateComponent} from '../update';

/** A component used to display partners' data */
@Component({
  selector: 'partner-retrieval',
  templateUrl: './retrieval.component.html',
  styleUrls: ['./retrieval.component.scss']
})
export class RetrievalComponent implements OnInit {

  /** Items to be displayed */
  items$: Observable<Page<Partner>>;

  columnsToDisplay = ['name', 'carModelType', 'actions'];

  constructor(
    private partnerRetrievalService: PartnerRetrievalService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchPartners();
  }
  /**  Fetches all partners */
  fetchPartners() {
    this.items$ = this.partnerRetrievalService.retrieveAll();
  }
  /** Opens the update dialog for the passed partner */
  openUpdateDialog(partner: Partner): void {
    const dialogRef = this.dialog.open(PartnerUpdateComponent, {
      width: '28rem',
      data: partner
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fetchPartners();
    }, error => {
      this.fetchPartners();
    });
  }
}
