import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Partner } from '../../common';

@Injectable({
  providedIn: 'root'
})
export class PartnerUpdateService {

  constructor(private http: HttpClient) { }

  /**
   * @param partner Data for the partner to be updated
   * @return An observable of the response
   */
  update(partner: Partner) {
    return this.http.put<any>('/api/admin/partners', partner);
  }
}
