import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Page } from "../../common/page";
import { Picture } from "../common";

@Injectable({
  providedIn: "root",
})
export class PictureGalleryService {
  constructor(private http: HttpClient) {}

  /** @param tags Tags to be used whe searching
   * @return An observable of the response */
  retrieveAll(
    tags: string,
    size: number,
    page: number
  ): Observable<Page<Picture>> {
    return this.http.get<any>(
      `/api/connected/pictures?tags=${tags}&size=${size}&page=${page}`
    );
  }
}
