import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CarModelPictureLinkRemovalService} from './carmodel-picturelinkremoval.service';
import {ActionState} from '../../../common';
import {CarModelPictureLinkRemovalData} from './carmodel-picturelinkremoval-data';

@Component({
  selector: 'app-carmodel-picturelinkremoval',
  templateUrl: './picturelinkremoval.component.html'
})
export class PictureLinkRemovalComponent implements OnInit {

  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CarModelPictureLinkRemovalData,
    private carModelPictureLinkRemovalService: CarModelPictureLinkRemovalService
  ) { }

  ngOnInit() {
  }

  /** Executed when the user confirms the action */
  onAction() {
    this.actionState.register(this.carModelPictureLinkRemovalService.removePictureLink(this.data.carModelId, this.data.pictureType));
  }

}
