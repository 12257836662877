import { Reference } from './reference';
import { ExportTaskConfig } from './export-task-config';
import { CarModelSearchRequest } from '../../carmodel-search/carmodel-search-request';
import { ExportTaskLastExecution } from './export-task-last-execution';

/** An export task */
export class ExportTask {
  constructor(public id: number,
    public version: number,
    public name: string,
    public partner: Reference,
    public config: ExportTaskConfig,
    public carModelSearchRequest: CarModelSearchRequest,
    public lastExecution: ExportTaskLastExecution) { }

  /** Whether this export task will be executed only once */
  get executedOnce() {
    return this.config.scheduling === ExportTaskConfig.onceScheduling;
  }

}
