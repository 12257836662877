import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { Zone } from '../zone';
import { ZoneService } from '../zone.service';

@Component({
  selector: 'app-zone-chooser',
  templateUrl: './chooser.component.html'
})
// TODO test
export class ChooserComponent implements OnInit {

  @Input('zone')
  public zone: FormControl;
  availableZones: Observable<Zone[]>;

  constructor(
    private zoneService: ZoneService
  ) { }

  ngOnInit() {
    this.availableZones = this.zoneService.retrieveAll();
  }
}
