import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { PictureSize } from '../picturesize';
import { PictureSizeService } from '../picturesize.service';

@Component({
  selector: 'app-picturesize-chooser',
  templateUrl: './chooser.component.html'
})
export class ChooserComponent implements OnInit {

  @Input()
  public pictureSize: FormControl;
  pictureSizes$: Observable<PictureSize[]>;

  constructor(
    private pictureSizeService: PictureSizeService
  ) { }

  ngOnInit() {
    this.pictureSizes$ = this.pictureSizeService.retrieveAll();
  }
}
