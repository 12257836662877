<div style="padding-left:1rem; padding-right:1rem;">
  <div class="table-bar" fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="1.25rem">
    <!-- Filters -->
    <div class="actions-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
      <button mat-raised-button color="primary" (click)="openFiltersDialog()" i18n>Set filters</button>
    </div>
    <!-- Result info -->
    <p *ngIf="itemsPage$ | async as itemsPage; else loadingP" i18n>{itemsPage.totalElements || 0, plural,
      =0 {no car models} =1 {1 car model} other { {{itemsPage.totalElements}} car models} } found</p>
    <ng-template #loadingP>
      <p fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
        <mat-spinner diameter="20"></mat-spinner>
        <span i18n>Loading car models...</span>
      </p>
    </ng-template>
    <p class="wrap primary" i18n>You noticed inconsistencies? Check <a
      href="https://microsite.europcar.com/ECI/mkt/ljc/PartnerCarVisuals.xls">PartnerCarVisuals.xls</a> and contact the
      Country Greenway data center or eci_q2c_set-up@europcar.com</p>
  </div>

  <div class="cards" fxLayout="row wrap" fxLayoutAlign="flex-start stretch" fxLayoutGap="1rem grid">
    <ng-container *ngFor="let item of (itemsPage$ | async)?.content as items">
      <app-carmodel-card [carModel]="item" (onRefreshRequired)="forceFetchCarModels()"></app-carmodel-card>
    </ng-container>
  </div>

  <mat-paginator (page)="onPaginationChange($event);" [length]="(itemsPage$ | async)?.totalElements"
                 [pageIndex]="pageRequest.pageIndex"
                 [pageSizeOptions]="pageSizeOptions" [pageSize]="pageRequest.pageSize"
                 showFirstLastButtons></mat-paginator>
</div>
