import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CarModelDeletionService {

  constructor(private http: HttpClient) { }

  /**
   * @param carModelId ID of the car model to be deleted
   * @return An observable of the response
   */
  deleteCarModel(carModelId: number) {
    return this.http.delete<any>(`/api/admin/carmodels/${carModelId}`);
  }
}
