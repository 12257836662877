type CompanyType = "EUROPCAR_NETWORK" | "ADVERTISING_AGENCY" | "OTHER";
/** Data necessary to register a user */
export class UserRegistration {

  constructor(public lastName: string,
    public firstName: string,
    public emailAddress: string,
    public phoneNumber: string,
    public company: string,
    public department: string,
    public position: string,
    public address1: string,
    public address2: string,
    public postalCode: string,
    public city: string,
    public country: string) { }
}
