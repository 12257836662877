import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { WithPassword } from './with-password';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private http: HttpClient) { }

  /** Updates a password
   * @param password The new password
   * @param token The authentication token
   * @return The response
   */
  update(password: WithPassword, token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };
    return this.http.put<any>('/api/connected/users/password', password, httpOptions);
  }
}
