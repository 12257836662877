<h1 class mat-dialog-title i18n>Periodic export task {actionType, select, deactivation {deactivation} activation {activation}}</h1>
<h4 *ngIf="actionState.success" class="success" i18n>Success</h4>
<h4 *ngIf="actionState.error" class="warn" i18n><strong>Error</strong></h4>
<div mat-dialog-content>
  <span *ngIf="actionState.initial"><span i18n>Do you want to {actionType, select, deactivation {deactivate} activation {activate}} the following periodic export task?</span><br /><strong>{{exportTask.name}}</strong></span>
  <span *ngIf="actionState.success" i18n>The periodic export task was successfully {actionType, select, deactivation {deactivated} activation {activated}}.</span>
  <span *ngIf="actionState.error" class="warn" i18n>The periodic export task could not be {actionType, select, deactivation {deactivated} activation {activated}}.</span>
</div>
<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
    <button *ngIf="actionState.initial" mat-button mat-dialog-close i18n>No</button>
    <button *ngIf="actionState.initial" mat-raised-button color="primary" (click)="onAction()" i18n>Yes</button>
    <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
    <button *ngIf="!actionState.initial" mat-button mat-dialog-close i18n>Back to the export tasks</button>
  </div>
</mat-dialog-actions>
