import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {CarModelType, Partner} from '../../common';
import {PartnerUpdateService} from './partner-update.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'partner-update',
  templateUrl: './update.component.html'
})
export class UpdateComponent implements OnInit {

  carModelTypes = [{label: 'RESAWEB', value: CarModelType[CarModelType.OPEN]}, {
    label: 'GDS',
    value: CarModelType[CarModelType.ALL]
  }];
  cmpformGroup: FormGroup;
  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public currentPartner: Partner,
    private formBuilder: FormBuilder,
    private partnerUpdateService: PartnerUpdateService
  ) {
  }

  ngOnInit() {
    this.cmpformGroup = this.formBuilder.group({
      carModelType: [this.currentPartner.carModelType, Validators.required]
    });
  }

  /** Convenience getter for easy access to form fields */
  get f() { return this.cmpformGroup.controls; }

  /** @return All data stored in the form */
  private formData() {
    const partner = new Partner();
    partner.id = this.currentPartner.id;
    partner.version = this.currentPartner.version;
    partner.name = this.currentPartner.name;
    partner.carModelType = this.f.carModelType.value;
    return partner;
  }

  /** Executed when the partner confirms the action */
  onSubmit() {
    this.actionState.register(this.partnerUpdateService.update(this.formData()));
  }

}
