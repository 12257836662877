import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

import {Page} from '../../../common';

import {AppUser, UserProfile} from '../../common';
import {UserRetrievalService} from './user-retrieval.service';
import {UserRetrievalRequest} from './user-retrieval-request';

import {UserCreationComponent} from '../creation';
import {UserUpdateComponent} from '../update';
import {UserDeletionComponent} from '../deletion';

/** A component used to display user accounts */
@Component({
  selector: 'app-user-retrieval',
  templateUrl: './retrieval.component.html',
  styleUrls: ['./retrieval.component.scss']
})
export class RetrievalComponent implements OnInit {

  @Input() profile: UserProfile;
  private previousPageRequest = null;
  pageRequest = { zone: null, pageIndex: 0, pageSize: 50 };
  /** Page of items to be displayed */
  itemsPage$: Observable<Page<AppUser>>;

  columnsToDisplay = ['name', 'username', 'emailAddress', 'phoneNumber', 'zone', 'actions'];
  pageSizeOptions = [20, 50, 100, 500];

  constructor(
    private userRetrievalService: UserRetrievalService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchUsers();
  }

  /** Called when the zone filter changes */
  resetPagination() {
    this.pageRequest.pageIndex = 0;
    this.fetchUsers();
  }

  /** Called when pagination data changes */
  onPaginationChange($event: PageEvent) {
    this.pageRequest.pageIndex = $event.pageIndex;
    this.pageRequest.pageSize = $event.pageSize;
    this.fetchUsers();
  }

  /**  Fetches a page of users with no condition */
  forceFetchUsers() {
    this.itemsPage$ = this.userRetrievalService.retrieveAll(
      new UserRetrievalRequest(this.pageRequest.zone, this.profile), this.pageRequest.pageSize, this.pageRequest.pageIndex);
  }

  /** @return Whether the current page request has been modified since the last request */
  isPageRequestChanged() {
    return !this.previousPageRequest ||
      this.previousPageRequest.zone !== this.pageRequest.zone ||
      this.previousPageRequest.pageIndex !== this.pageRequest.pageIndex ||
      this.previousPageRequest.pageSize !== this.pageRequest.pageSize;
  }

  /** Fetches a page of users, if necessary */
  fetchUsers() {
    if (this.isPageRequestChanged()) {
      this.forceFetchUsers();
      this.previousPageRequest = { ...this.pageRequest };
    }
  }

  /** Opens the creation dialog */
  openCreationDialog(): void {
    const dialogRef = this.dialog.open(UserCreationComponent, {
      width: '28rem',
      data: this.profile
    });
    dialogRef.afterClosed().subscribe(result => {
      this.forceFetchUsers();
    }, error => {
      this.forceFetchUsers();
    });
  }

  /** Opens the update dialog for the passed user */
  openUpdateDialog(user: AppUser): void {
    const dialogRef = this.dialog.open(UserUpdateComponent, {
      width: '28rem',
      data: user
    });
    dialogRef.afterClosed().subscribe(result => {
      this.forceFetchUsers();
    }, error => {
      this.forceFetchUsers();
    });
  }

  /** Opens the deletion dialog for the passed user */
  openDeletionDialog(user: AppUser): void {
    const dialogRef = this.dialog.open(UserDeletionComponent, {
      width: '25rem',
      data: user
    });
    dialogRef.afterClosed().subscribe(result => {
      this.forceFetchUsers();
    }, error => {
      this.forceFetchUsers();
    });
  }

}
