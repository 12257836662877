import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonPartnerRetrievalService {

  constructor(private http: HttpClient) { }

  /** @return An observable of the response */
  retrieveAll() {
    return this.http.get<any>('/api/admin/partners').pipe(share());
  }
}
