import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';

import {Picture} from '../../common';
import {ActionState} from '../../../common';

import {PictureDeletionComponent} from '../../management/deletion';

/** A component used to display the gallery and select a picture in it */
@Component({
  selector: 'app-picture-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class PictureCardComponent implements OnInit {

  /** Whether the chooser mode should be actived */
  @Input('chooserMode')
  public chooserMode: boolean;
  /** Data for the picture */
  @Input('picture')
  public picture: Picture;
  /** Whether this card should be selected */
  @Input('selected')
  public selected: boolean;

  @Output()
  onSelected = new EventEmitter();
  @Output()
  onDeleted = new EventEmitter();

  deletionActionState: ActionState = new ActionState();

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  /** Notifies that the card was selected */
  public notifySelection() {
    this.onSelected.emit(this.picture.uuid);
  }

  /** Opens the deletion dialog for this picture */
  openDeletionDialog(): void {
    const dialogRef = this.dialog.open(PictureDeletionComponent, {
      width: '25rem',
      data: this.picture
    });
    dialogRef.afterClosed().subscribe(result => {
      this.notifyDeletion();
    }, error => {
      this.notifyDeletion();
    });
  }

  /** Notifies that the picture was deleted */
  public notifyDeletion() {
    this.onDeleted.emit('');
  }
}
