import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {ExportTaskConfig, PictureConfig} from '../../common';
import {CarModelSearchRequest} from '../../../carmodel-search/carmodel-search-request';

@Component({
  selector: 'app-exporttask-edition',
  templateUrl: './edition.component.html',
  styleUrls: ['./edition.component.scss']
})
export class EditionComponent implements OnInit {

  availableSchedulings = ExportTaskConfig.availableSchedulings;
  availablePictureFormats = PictureConfig.availablePictureFormats;
  availablePictureTypes = PictureConfig.availablePictureTypes;
  availableRanks = CarModelSearchRequest.availableRanks;

  @Input() executedOnce: boolean;
  @Input() cmpformGroup: FormGroup;

  constructor() { }

  ngOnInit() { }

}
