import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

/** A utility used to define the state of an action */
export class ActionState {
  public initial: boolean = true;
  public loading: boolean = false;
  public success: boolean = false;
  public error: boolean = false;
  public errorCode: number;
  public errorDetails: string;
  public data: any;

  /** Registers a new observable corresponding to an action */
  register(observable: Observable<any>) {
    this.initial = true;
    this.loading = true;
    this.success = false;
    this.error = false;
    this.errorCode = -1;
    this.errorDetails = null;
    observable.subscribe(
      data => {
        this.initial = false;
        this.loading = false;
        this.success = true;
        this.error = false;
        this.errorCode = -1;
        this.errorDetails = null;
      },
      error => {
        this.initial = false;
        this.loading = false;
        this.success = false;
        this.error = true;
        this.errorCode = error.status;
        this.errorDetails = error && error.error ? error.error.message : null;
      });
  }

  /** Cleans this action state, so that a new action can start */
  clean() {
    this.initial = true;
    this.loading = false;
    this.success = false;
    this.error = false;
    this.errorCode = null;
    this.errorDetails = null;
  }
}
