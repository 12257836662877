import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Credentials } from './credentials';
import { AuthenticationToken } from './authentication-token';

const TOKEN_ITEM_NAME = 'token';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private tokenSource: BehaviorSubject<AuthenticationToken>;
  public token: Observable<AuthenticationToken>;

  constructor(private http: HttpClient) {
    const initialToken = JSON.parse(sessionStorage.getItem(TOKEN_ITEM_NAME))
      || new AuthenticationToken(false, false, null, null, false, false, null);
    this.tokenSource = new BehaviorSubject<AuthenticationToken>(initialToken);
    this.token = this.tokenSource.asObservable();
  }

  /** @return The actual token */
  public get tokenValue(): AuthenticationToken {
    return this.tokenSource.value;
  }
  /** Authenticates a user given his credentials
   * @param credentials User credentials
   * @return The authentication token
   */
  login(credentials: Credentials) {
    this.http.post<AuthenticationToken>('/api/open/users/authentication', credentials)
      .subscribe(
        authenticationToken => {
          sessionStorage.setItem(TOKEN_ITEM_NAME, JSON.stringify(authenticationToken));
          this.tokenSource.next(authenticationToken);
        },
        error => {
          sessionStorage.removeItem(TOKEN_ITEM_NAME);
          this.tokenSource.next(new AuthenticationToken(false, true, null, null, false, false, null));
        }
      );
  }
  /** Clears the current token */
  logout() {
    sessionStorage.removeItem(TOKEN_ITEM_NAME);
    this.tokenSource.next(new AuthenticationToken(false, false, null, null, false, false, null));
  }
}
