import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PictureDeletionService {

  constructor(private http: HttpClient) { }

  /**
   * @param id ID of the picture to be deleted
   * @return An observable of the response
   */
  deletePicture(id: number) {
    return this.http.delete<any>(`/api/admin/pictures/${id}`);
  }
}
