import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CarModelRetrievalComponent } from './carmodel/management/retrieval';
import { PictureRetrievalComponent } from './picture/management/retrieval';
import { LoginComponent } from './user/self/login';
import { UserRegistrationComponent } from './user/self/registration';
import { NewPasswordComponent } from './user/self/newpassword';
import { PasswordComponent } from './user/self/password';
import { UserManagementComponent } from './user/admin/management';
import { PartnerManagementComponent } from './partner/admin/management';
import { ExportTaskManagementComponent } from './exporttask/admin/management';

import { AuthenticationGuard, AdminAuthenticationGuard, InternalAuthenticationGuard } from './authentication';

const routes: Routes = [
  { path: '', component: CarModelRetrievalComponent, canActivate: [AuthenticationGuard] },

  { path: 'user/self/login', component: LoginComponent },
  { path: 'user/self/registration', component: UserRegistrationComponent },
  { path: 'user/self/newpassword', component: NewPasswordComponent },
  { path: 'user/self/password', component: PasswordComponent },

  { path: 'user/admin/management', component: UserManagementComponent, canActivate: [AdminAuthenticationGuard] },
  { path: 'partner/admin/management', component: PartnerManagementComponent, canActivate: [AdminAuthenticationGuard] },
  { path: 'exporttask/admin/management', component: ExportTaskManagementComponent, canActivate: [AdminAuthenticationGuard] },

  { path: 'carmodel/management/retrieval', component: CarModelRetrievalComponent, canActivate: [AuthenticationGuard] },
  { path: 'picture/management/retrieval', component: PictureRetrievalComponent, canActivate: [InternalAuthenticationGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
