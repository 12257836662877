import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';

import { ZoneFilterComponent } from './filter';
import { ZoneChooserComponent } from './chooser';
import { ZoneMultiChooserComponent } from './multichooser';

@NgModule({
  declarations: [
    ZoneFilterComponent,
    ZoneChooserComponent,
    ZoneMultiChooserComponent
  ],
  imports: [CoreModule],
  exports: [ZoneFilterComponent,
    ZoneChooserComponent,
    ZoneMultiChooserComponent]
})
export class ZoneModule { }
