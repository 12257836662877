<div>
  <div class="table-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.625rem">
    <p>
      <button mat-raised-button color="primary" (click)="openCreationDialog()" i18n>New</button>
    </p>
    <p *ngIf="itemsPage$ | async as itemsPage; else loadingP">
      <span *ngIf="executedOnce; else newPeriodicExportTaskLabel" i18n>{itemsPage.totalElements || 0, plural, =0 {no one-time export tasks} =1 {1 one-time export task} other { {{itemsPage.totalElements}} one-time export tasks} } found</span>
      <ng-template #newPeriodicExportTaskLabel i18n>{itemsPage.totalElements || 0, plural, =0 {no periodic export tasks} =1 {1 periodic export task} other { {{itemsPage.totalElements}} periodic export tasks} } found</ng-template>

    </p>
    <ng-template #loadingP>
      <p fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
        <mat-spinner diameter="20"></mat-spinner><span i18n>Loading export tasks...</span>
      </p>
    </ng-template>
  </div>
  <div>
    <table mat-table [dataSource]="(itemsPage$ | async)?.content">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef i18n>Name</th>
        <td mat-cell *matCellDef="let exportTask">{{exportTask.name}}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef i18n>State</th>
        <td mat-cell *matCellDef="let exportTask">
          <ng-container *ngIf="executedOnce;">
            <ng-container i18n>created on {{exportTask.creationDate | date:'short'}}</ng-container>
          </ng-container>
          <ng-container *ngIf="!executedOnce">
            <span [ngSwitch]="exportTask.config.active">
              <strong class="primary" *ngSwitchCase="true" i18n>active</strong>
              <strong *ngSwitchDefault i18n>inactive</strong>
            </span>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="execution">
        <th *matHeaderCellDef mat-header-cell>
          <span *ngIf="executedOnce" i18n>Execution</span>
          <span *ngIf="!executedOnce" i18n>Last execution</span></th>
        <td mat-cell *matCellDef="let exportTask">

          <span *ngIf="exportTask.lastExecution; else neverExecuted">
            <span [ngSwitch]="exportTask.lastExecution.executionState">
              <span *ngSwitchCase="'PENDING'" i18n>pending</span>
              <span *ngSwitchCase="'PROCEEDING'" i18n>proceeding</span>
              <span *ngSwitchCase="'SUCCESS'"><span i18n>success on</span>&nbsp;{{exportTask.lastExecution.lastExecutionDate | date:'short'}}</span>
              <span *ngSwitchCase="'FAILURE'" class="warn"><span i18n>failure on</span>&nbsp;{{exportTask.lastExecution.lastExecutionDate | date:'short'}}</span>
            </span>
          </span>
          <ng-template #neverExecuted>
            <span i18n>pending</span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let exportTask">
          <div fxLayout="row" fxLayoutAlign="end center">
            <button *ngIf="!executedOnce" (click)="openActivationDialog(exportTask)" mat-button><span *ngIf="exportTask.config.active; else activateBtLabel" i18n>Deactivate</span>
              <ng-template #activateBtLabel i18n>Activate</ng-template>
            </button>
            <button *ngIf="!executedOnce" mat-button color="primary" (click)="openUpdateDialog(exportTask)" i18n>Edit</button>
            <button *ngIf="!executedOnce" mat-button color="warn" i18n (click)="openDeletionDialog(exportTask)">Delete</button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    </table>
  </div>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [length]="(itemsPage$ | async)?.totalElements" [pageIndex]="pageRequest.pageIndex" [pageSize]="pageRequest.pageSize" (page)="onPaginationChange($event);"></mat-paginator>
</div>
