<form [formGroup]="cmpformGroup">
  <mat-form-field appearance="fill" fxFlex>
    <input matInput readonly="true" [value]="cmpformGroup.controls.file.value.name || ''" (click)="fileInput.click()" i18n-placeholder placeholder="Choose file to upload" />
    <button *ngIf="!actionState.loading" mat-icon-button matSuffix i18n-aria-label aria-label="Choose file to upload" (click)="fileInput.click()">
      <mat-icon class="primary">folder_open</mat-icon>
    </button>
    <span *ngIf="actionState.loading" matSuffix>
      <mat-spinner diameter="20"></mat-spinner>
    </span>
    <mat-hint><span i18n>Uploaded visuals will be tagged with:</span>&nbsp;{{name}}</mat-hint>
  </mat-form-field>
  <input hidden #fileInput type="file" name="file" (change)="onFileChange($event); onSubmit();" required accept=".png" />
</form>
<p *ngIf="validationError" class="warn">
  <mat-icon inline="true" color="warn" aria-hidden="true">error</mat-icon>&nbsp;
  <span i18n *ngIf="actionState.errorDetails === 'weight'">The file weight is lower than 400 KB.</span>
  <span i18n *ngIf="actionState.errorDetails === 'type'">The format of the file is not PNG.</span>
  <span i18n *ngIf="actionState.errorDetails === 'size'">The dimension of the image does not respect the requirements.</span>
  <span *ngIf="actionState.errorDetails === 'ratio'" i18n>The ratio of the image does not respect the requirements.</span>
  <span i18n *ngIf="actionState.errorDetails === 'alpha'">The background of the image is not transparent.</span>
  <span i18n>&nbsp;Please, upload an other image.</span>
</p>
<p *ngIf="actionState.success">
  <mat-icon inline="true" color="primary" aria-hidden="true">done</mat-icon>&nbsp;
  <span class="primary" i18n>The file was successfully uploaded.</span><br />
  <span i18n>The visual is now selected and available in the gallery.</span>
</p>
<p *ngIf="actionState.error && !validationError">
  <mat-icon inline="true" color="warn" aria-hidden="true">error</mat-icon>&nbsp;
  <span class="warn" i18n>The file could not be uploaded.<br/>This may happen if the file weight is greater than 5 MB.
    <br/>Please, upload an other image or try again later.</span>
</p>
<p i18n>Requirements</p>
<ul>
  <li i18n>Only <strong>PNG</strong> files are accepted.</li>
  <li i18n>Minimum file weight is <strong>400 KB</strong> and maximum file weight is <strong>5 MB</strong>.</li>
  <li i18n>Minimum image size is <strong>900x600 pixels</strong>.</li>
  <li i18n>Ratio of the image must be 3:2 (same ratio as images having the following dimension: 900x600 pixels).</li>
  <li i18n><strong>Background</strong> must be <strong>transparent</strong>.</li>
  <li i18n><strong>No licence plate</strong> must be visible.</li>
  <li i18n>The vehicle must fit the maximum image space.</li>
  <li i18n>A high-level visual quality (perfect sharpness) for the image is required.</li>
  <li i18n>When reducing the image, use the best compression algorithm (bicubic sharper).</li>
</ul>
