import {Zone} from '../../zone/zone';
import {CarModelDescription} from './carmodel-description';
import {ExportConfig} from './export-config';
import {RelatedPictures} from './related-pictures';

/** A car model */
export class CarModel {
  constructor(public id: number,
              public version: number,
              public zone: Zone,
              public zoneName: string,
              public modelId: string,
              public creationDate: Date,
              public lastModificationDate: Date,
              public description: CarModelDescription,
              public exportConfig: ExportConfig,
              public relatedPictures: RelatedPictures,
              public tags: string) { }
}
