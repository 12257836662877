import {NgModule} from '@angular/core';

import {CoreModule} from './core.module';
import {AppRoutingModule} from './app-routing.module';
import {AuthenticationModule} from './authentication/authentication.module';
import {UserModule} from './user/user.module';
import {PartnerModule} from './partner/partner.module';
import {CarModelModule} from './carmodel/carmodel.module';
import {ZoneModule} from './zone/zone.module';
import {PictureModule} from './picture/picture.module';
import {ExportTaskModule} from './exporttask/exporttask.module';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpError401Interceptor, TokenInterceptor} from './authentication';

import {AppComponent} from './app.component';

import {HeaderComponent} from './navigation/header/header.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    UserModule,
    PartnerModule,
    CarModelModule,
    ZoneModule,
    PictureModule,
    AuthenticationModule,
    ExportTaskModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpError401Interceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
