import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';

import { CarMakerChooserComponent } from './carmaker/chooser';
import { PartnerChooserComponent } from './partner/chooser';

@NgModule({
  imports: [
    CoreModule
  ],
  declarations: [
    CarMakerChooserComponent,
    PartnerChooserComponent
  ],
  entryComponents: [],
  exports: [
    CarMakerChooserComponent,
    PartnerChooserComponent
  ]
})
export class CommonModule { }
