import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {CarModel, CarModelDescription, ExportConfig} from '../../common';
import {CarModelUpdateService} from './carmodel-update.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-carmodel-update',
  templateUrl: './update.component.html'
})
export class UpdateComponent implements OnInit {

  availableModelRanks = [0, 1, 2, 3];
  availableSeats = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56];
  availableDoors = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  cmpformGroup: FormGroup;
  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public currentCarModel: CarModel,
    private formBuilder: FormBuilder,
    private carModelUpdateService: CarModelUpdateService
  ) { }

  ngOnInit() {
    const description = this.currentCarModel.description;
    const exportConfig = this.currentCarModel.exportConfig;
    const required255 = Validators.compose([Validators.required, Validators.maxLength(255)]);
    const optional255 = Validators.maxLength(255);
    const required5 = Validators.compose([Validators.required, Validators.maxLength(5)]);
    const required4 = Validators.compose([Validators.required, Validators.maxLength(4)]);
    const optional2 = Validators.maxLength(2);
    const optional1 = Validators.maxLength(1);
    this.cmpformGroup = this.formBuilder.group({

      zone: [this.currentCarModel.zone, Validators.required],
      modelId: [this.currentCarModel.modelId, required5],

      label: [description.label, required255],
      alternativeLabel: [description.alternativeLabel, optional255],
      acriss: [description.acriss, required4],
      carMaker: [description.carMaker, Validators.required],
      category: [description.category, optional1],
      seats: [description.seats],
      doors: [description.doors],
      bootCapacity: [description.bootCapacity, optional2],
      modelRank: [description.modelRank],

      available: [exportConfig.available],
      restricted: [exportConfig.restricted]
    });
  }

  /** Convenience getter for easy access to form fields */
  get f() { return this.cmpformGroup.controls; }

  /** @return All data stored in the form */
  private formData() {
    const description = new CarModelDescription(this.f.label.value, this.f.alternativeLabel.value, this.f.acriss.value,
      this.f.carMaker.value, null, this.f.category.value, this.f.seats.value, this.f.doors.value, this.f.bootCapacity.value,
      this.f.modelRank.value);
    const exportConfig = new ExportConfig(this.f.available.value, this.f.restricted.value);
    return new CarModel(this.currentCarModel.id, this.currentCarModel.version,
      this.f.zone.value, null, this.f.modelId.value, null, null, description, exportConfig, null, null);
  }

  /** Executed when the user confirms the action */
  onSubmit() {
    this.actionState.register(this.carModelUpdateService.update(this.formData()));
  }

}
