import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { PictureConfig } from './picture-config';

@Injectable({
  providedIn: 'root'
})
export class PictureConfigService {

  private pictureConfigSource: BehaviorSubject<PictureConfig>;
  public pictureConfig: Observable<PictureConfig>;
  private initialized: boolean = false;

  constructor(private http: HttpClient) {
    this.pictureConfigSource = new BehaviorSubject<PictureConfig>(null);
    this.pictureConfig = this.pictureConfigSource.asObservable();
  }

  /** @return The actual picture config value */
  public get pictureConfigValue(): PictureConfig {
    return this.pictureConfigSource.value;
  }

  /** Retrieves the configuration related to pictures */
  retrieve() {
    if (!this.initialized) {
      this.http.get<any>('/api/connected/pictures/config').subscribe(
        pictureConfig => {
          this.pictureConfigSource.next(pictureConfig);
        },
        error => { }
      );
      this.initialized = true;
    }
  }
}
