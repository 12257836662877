import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExportTaskActivationService {

  constructor(private http: HttpClient) { }

  /**
   * @param id ID of the export task to be updated
   * @param active Whether to activate or deactivate the export task
   * @return An observable of the response
   */
  activateExportTask(id: number, active: boolean) {
    return this.http.put<any>(`/api/admin/exporttasks/${id}/config/active/${active}`, {});
  }
}
