import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { CarMaker } from '../carmaker';
import { CarMakerService } from '../carmaker.service';

@Component({
  selector: 'app-carmaker-chooser',
  templateUrl: './chooser.component.html'
})
export class ChooserComponent implements OnInit {

  @Input('carMaker')
  public carMaker: FormControl;
  carMakers: Observable<CarMaker[]>;

  @Input()
  includeNull: boolean = false;

  constructor(
    private carMakerService: CarMakerService
  ) { }

  ngOnInit() {
    this.carMakers = this.carMakerService.retrieveAll();
  }
}
