import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {AppUser, UserProfile} from '../../common';
import {UserCreationService} from './user-creation.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-user-creation',
  templateUrl: './creation.component.html'
})
export class CreationComponent implements OnInit {

  userProfiles = UserProfile;
  cmpformGroup: FormGroup;
  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public profile: UserProfile,
    private formBuilder: FormBuilder,
    private userCreationService: UserCreationService
  ) { }

  ngOnInit() {
    const required256 = Validators.compose([Validators.required, Validators.maxLength(256)]);
    const optional256 = Validators.maxLength(256);
    this.cmpformGroup = this.formBuilder.group({
      lastName: ['', required256],
      firstName: ['', required256],
      username: ['', Validators.compose([Validators.required, Validators.pattern(AppUser.USERNAME_PATTERN)])],
      emailAddress: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(128)])],
      phoneNumber: ['', Validators.maxLength(20)],
      department: ['', optional256],
      zone: ['FR', Validators.required]
    });
  }

  /** Convenience getter for easy access to form fields */
  get f() { return this.cmpformGroup.controls; }

  /** @return All data stored in the form */
  private formData() {
    const user = new AppUser();
    user.lastName = this.f.lastName.value;
    user.firstName = this.f.firstName.value;
    user.username = this.f.username.value;
    user.emailAddress = this.f.emailAddress.value;
    user.phoneNumber = this.f.phoneNumber.value;
    user.department = this.f.department.value;
    user.zone = this.f.zone.value;
    user.profile = this.profile;
    return user;
  }

  /** Whether a validation error occured */
  get validationError() {
    return this.actionState.error && this.actionState.errorCode === 409;
  }

  /** Executed when the user confirms the action */
  onSubmit() {
    this.actionState.register(this.userCreationService.create(this.formData()));
  }

}
