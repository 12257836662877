import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CarModelPictureLinkUpdateService} from './carmodel-picturelinkupdate.service';
import {ActionState} from '../../../common';
import {CarModelPictureLinkUpdateData} from './carmodel-picturelinkupdate-data';

@Component({
  selector: 'app-carmodel-picturelinkupdate',
  templateUrl: './picturelinkupdate.component.html',
  styleUrls: ['./picturelinkupdate.component.scss']
})
export class PictureLinkUpdateComponent implements OnInit {

  actionState: ActionState = new ActionState();
  public value: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CarModelPictureLinkUpdateData,
    private carModelPictureLinkUpdateService: CarModelPictureLinkUpdateService
  ) { }

  ngOnInit() { }

  /** Executed when the user confirms the action */
  onAction() {
    this.actionState.register(this.carModelPictureLinkUpdateService.updatePictureLink(this.data.carModelId, this.data.pictureType, this.value));
  }

  /** Notifies a new selected value for a picture
   * @param newValue The UUID of a picture */
  onNewValue(newValue) {
    this.value = newValue;
  }

}
