import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {CarModelPictureLinkUpdateComponent, CarModelPictureLinkUpdateData} from '../../../picturelinkupdate';
import {CarModelPictureLinkRemovalComponent, CarModelPictureLinkRemovalData} from '../../../picturelinkremoval';

/** A component used to display visuals of a car model */
@Component({
  selector: 'app-carmodel-relatedpicture',
  templateUrl: './relatedpicture.component.html',
  styleUrls: ['./relatedpicture.component.scss']
})
export class CarModelRelatedPictureComponent implements OnInit {

  @Input('carModelId')
  public carModelId: number;
  @Input('carModelLabel')
  public carModelLabel: string;
  @Input('type')
  public type: string;
  @Input('value')
  public value: string;
  @Output() onUpdate = new EventEmitter();

  constructor(public dialog: MatDialog) { }

  ngOnInit() { }

  /** Notifies that the value was updated */
  private notifyUpdate(newValue) {
    this.value = newValue;
    this.onUpdate.emit(newValue);
  }

  /** Opens the update link dialog for this picture */
  openUpdatePictureLinkDialog(): void {
    const dialogRef = this.dialog.open(CarModelPictureLinkUpdateComponent, {
      width: '50rem',
      data: new CarModelPictureLinkUpdateData(this.carModelId, this.carModelLabel, this.type)
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notifyUpdate(result);
      }
    }, error => { });
  }

  /** Opens the remove link dialog for this picture */
  openRemovePictureLinkDialog(): void {
    const dialogRef = this.dialog.open(CarModelPictureLinkRemovalComponent, {
      width: '33rem',
      data: new CarModelPictureLinkRemovalData(this.carModelId, this.type)
    });
    dialogRef.afterClosed().subscribe(success => {
      if (success) {
        this.notifyUpdate(null);
      }
    }, error => { });
  }

}
