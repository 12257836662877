import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CarMaker } from './carmaker';

@Injectable({
  providedIn: 'root'
})
export class CarMakerService {

  constructor(private http: HttpClient) { }

  /** @return An observable of all the car makers */
  retrieveAll() {
    return this.http.get<Array<CarMaker>>('/api/connected/carmakers');
  }
}
