import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Zone } from './zone';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  constructor(private http: HttpClient) { }

  /** @return An observable of all the zones */
  retrieveAll() {
    return this.http.get<Array<Zone>>('/api/connected/zones');
  }
}
