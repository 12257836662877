import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';

import {
  AuthenticationService, AuthenticationGuard, AdminAuthenticationGuard,
  HttpError401Interceptor, TokenInterceptor,
  UserConnectedDirective, AdminConnectedDirective, InternalConnectedDirective
} from './index';

@NgModule({
  declarations: [
    UserConnectedDirective, AdminConnectedDirective, InternalConnectedDirective
  ],
  imports: [
    CoreModule
  ],
  exports: [
    UserConnectedDirective, AdminConnectedDirective, InternalConnectedDirective
  ]
})
export class AuthenticationModule { }
