<h1 class mat-dialog-title i18n>Car model update</h1>
<h4 *ngIf="actionState.success" class="success" i18n>Success</h4>
<h4 *ngIf="actionState.error" class="warn" i18n><strong>Error</strong></h4>
<div mat-dialog-content>
  <form *ngIf="actionState.initial" [formGroup]="cmpformGroup">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label i18n>Basics</ng-template>
        <div class="mg-top">
          <mat-form-field>
            <mat-label i18n>Greenway car model name</mat-label>
            <input matInput formControlName="label" required />
            <mat-error *ngIf="cmpformGroup.controls.label.errors" i18n>Required and must not exceed 255 characters.</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-label i18n>Online vehicle name</mat-label>
          <input matInput formControlName="alternativeLabel" />
          <mat-error *ngIf="cmpformGroup.controls.alternativeLabel.errors" i18n>Must not exceed 255 characters.</mat-error>
        </mat-form-field>
        <app-zone-chooser [zone]="cmpformGroup.controls.zone"></app-zone-chooser>
        <app-carmaker-chooser [carMaker]="cmpformGroup.controls.carMaker"></app-carmaker-chooser>
        <mat-form-field>
          <mat-label i18n>Car model ID</mat-label>
          <input matInput formControlName="modelId" required />
          <mat-error *ngIf="cmpformGroup.controls.modelId.errors" i18n>Required and must not exceed 5 characters.</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>ACRISS code</mat-label>
          <input matInput formControlName="acriss" required />
          <mat-error *ngIf="cmpformGroup.controls.acriss.errors" i18n>Required and must contain 4 characters.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>Rank</mat-label>
          <mat-select formControlName="modelRank">
            <mat-option i18n>&lt;None&gt;</mat-option>
            <mat-option *ngFor="let item of availableModelRanks" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>

      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label i18n>Specs</ng-template>
        <div class="mg-top">
          <mat-form-field>
            <mat-label i18n>Car category</mat-label>
            <input matInput formControlName="category" />
            <mat-error *ngIf="cmpformGroup.controls.category.errors" i18n>Must not exceed 1 character.</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-label i18n>Seats</mat-label>
          <mat-select formControlName="seats">
            <mat-option i18n>&lt;None&gt;</mat-option>
            <mat-option *ngFor="let item of availableSeats" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>Doors</mat-label>
          <mat-select formControlName="doors">
            <mat-option i18n>&lt;None&gt;</mat-option>
            <mat-option *ngFor="let item of availableDoors" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>Boot capacity</mat-label>
          <input matInput formControlName="bootCapacity" />
          <mat-error *ngIf="cmpformGroup.controls.bootCapacity.errors" i18n>Must not exceed 2 characters.</mat-error>
        </mat-form-field>

      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label i18n>Advanced</ng-template>

        <div class="mg-top">
          <mat-slide-toggle formControlName="available" i18n>
            Available for export
          </mat-slide-toggle>
        </div>
        <div class="mg-bottom">
          <mat-slide-toggle formControlName="restricted" i18n>
            Restricted access (not for public use)
          </mat-slide-toggle>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
  <span *ngIf="actionState.success" i18n>The car model was successfully updated.</span>
  <span *ngIf="actionState.error" class="warn" i18n>The car model could not be updated.<br />Please, try again later.</span>
</div>
<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
    <button *ngIf="actionState.initial" mat-raised-button color="primary" [disabled]="cmpformGroup.invalid" (click)="onSubmit()" i18n>Update</button>
    <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
    <button mat-button mat-dialog-close i18n>Back to car models</button>
  </div>
</mat-dialog-actions>
