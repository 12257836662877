import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {share} from 'rxjs/operators';

import {PictureUploadService} from './pictureupload.service';
import {ActionState} from '../../common';

/** A component used to upload a picture */
@Component({
  selector: 'app-picture-upload',
  templateUrl: './pictureupload.component.html',
  styleUrls: ['./pictureupload.component.scss']
})
export class PictureUploadComponent implements OnInit {

  @Input('name')
  public name: string;
  @Output() onUploaded = new EventEmitter();

  private uuid: string;

  cmpformGroup: FormGroup;
  actionState: ActionState = new ActionState();

  constructor(private formBuilder: FormBuilder, private pictureUploadService: PictureUploadService) { }

  ngOnInit() {
    this.cmpformGroup = this.formBuilder.group({
      file: ['']
    });
  }

  /** Executed when the user selects a file to be uploaded */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.cmpformGroup.controls.file.setValue(file);
      this.cmpformGroup.controls.file.markAsDirty();
    }
  }

  /** @return All data stored in the form */
  private formData() {
    const formData = new FormData();
    formData.append('name', this.name);
    formData.append('file', this.cmpformGroup.controls.file.value);
    return formData;
  }

  /** Executed when the user confirms the action */
  onSubmit() {
    const observable = this.pictureUploadService.uploadPicture(this.formData()).pipe(share());
    this.actionState.register(observable);
    observable.subscribe(picture => this.notifyUpload(picture), () => { });
  }

  /** Notifies that the upload was done, and passes the uploaded picture UUID to the parent component
   * @param picture Data on the uploaded picture */
  private notifyUpload(picture) {
    this.uuid = picture.uuid;
    this.onUploaded.emit(this.uuid);
  }

  /** Whether a validation error occured */
  get validationError() {
    return this.actionState.error && this.actionState.errorCode === 412;
  }

}
