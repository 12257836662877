import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PictureSize } from './picturesize';

@Injectable({
  providedIn: 'root'
})
export class PictureSizeService {

  constructor(private http: HttpClient) { }

  /** @return An observable of all the picture sizes */
  retrieveAll() {
    return this.http.get<Array<PictureSize>>('/api/admin/picturesizes');
  }
}
