import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ExportTask } from '../../common';

@Injectable({
  providedIn: 'root'
})
export class ExportTaskCreationService {

  constructor(private http: HttpClient) { }

  /**
   * @param exportTask Data for the export task to be created
   * @return An observable of the response
   */
  create(exportTask: ExportTask) {
    return this.http.post<any>('/api/admin/exporttasks', exportTask);
  }
}
