<ng-container *ngIf="actionState.initial">
  <div mat-dialog-content>

    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="1.25rem">
      <div fxFlex="30">
        <h1 i18n>Add/change visual</h1>
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.625rem">
          <div class="picture-wrapper border-primary" fxLayout="row" fxLayoutAlign="center center">
            <app-picture-image *ngIf="value; else noSelectedVisual" [uuid]="value" height="7rem"></app-picture-image>
            <ng-template #noSelectedVisual i18n>No visual selected</ng-template>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
            <button [disabled]="!value" mat-raised-button color="primary" (click)="onAction()" i18n>Use this visual</button>
            <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
          </div>
          <button mat-raised-button mat-dialog-close i18n>Back to car models</button>
        </div>
      </div>
      <div fxFlex>
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label i18n>Upload new visual</ng-template>
            <ng-template matTabContent>
              <app-picture-upload (onUploaded)="onNewValue($event)" [name]="data.carModelLabel"></app-picture-upload>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label i18n>Select visual in gallery</ng-template>
            <ng-template matTabContent>
              <div class="gallery">
                <app-picture-gallery [chooserMode]="true" (onSelected)="onNewValue($event)" [uuid]="value"></app-picture-gallery>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="actionState.success">
  <h4 class="success" i18n>Success</h4>
  <div mat-dialog-content>
    <span i18n>The visual was added to the car model.</span>
  </div>
  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="value" i18n>Back to car models</button>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="actionState.error">
  <h4 class="warn" i18n><strong>Error</strong></h4>
  <div mat-dialog-content>
    <span class="warn" i18n>The visual could not be added to the car model.<br />Please, try again later.</span>
  </div>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close i18n>Back to car models</button>
  </mat-dialog-actions>
</ng-container>
