import { Component, OnInit } from '@angular/core';

/** A component used to search and display car models */
@Component({
  selector: 'app-picture-retrieval',
  templateUrl: './retrieval.component.html'
})
export class RetrievalComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
