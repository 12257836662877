import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {CarModelSearchRequest} from '../../../carmodel-search/carmodel-search-request';

/** A component displaying the form containing the criteria to search car models */
@Component({
  selector: 'app-carmodel-search-request',
  templateUrl: './carmodel-search-request.component.html'
})
export class CarModelSearchRequestComponent implements OnInit {

  availableRanks = CarModelSearchRequest.availableRanks;
  cmpformGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CarModelSearchRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public request: CarModelSearchRequest,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    const optional12 = Validators.maxLength(12);
    const optional5 = Validators.maxLength(5);
    this.cmpformGroup = this.formBuilder.group({
      modelId: [this.request.modelId, optional12],
      zones: [this.request.zones],
      carMaker: [this.request.carMaker],
      vehicleClasses: [this.request.vehicleClasses()],
      acrissCode: [this.request.acrissCode(), optional5],
      isVehicleClasses: [this.request.isVehicleClasses()],
      modelRanks: [this.request.modelRanks],
      isWithoutMainPicture: [this.request.isWithoutMainPicture],
      isIncludeOnlyAvailable: [this.request.isIncludeOnlyAvailable],
      createdOn: [this.request.createdOn],
      lastModifiedOn: [this.request.lastModifiedOn],
      createdAfter: [this.request.createdAfter],
      lastModifiedAfter: [this.request.lastModifiedAfter]
    });
  }

  /** @return All data stored in the form */
  private formData() {
    const f = this.cmpformGroup.controls;
    return new CarModelSearchRequest(null, null, f.modelId.value,
      f.createdAfter.value, f.lastModifiedAfter.value, f.createdOn.value,
      f.lastModifiedOn.value, f.carMaker.value, CarModelSearchRequest.normalizeMultiSelectValue(f.zones.value), CarModelSearchRequest.computeAcrisses(f.isVehicleClasses.value, f.vehicleClasses.value, f.acrissCode.value),
      CarModelSearchRequest.normalizeMultiSelectValue(f.modelRanks.value), f.isWithoutMainPicture.value, f.isIncludeOnlyAvailable.value
    );
  }

  /** Executed when the user confirms the action */
  onSubmit() {
    this.dialogRef.close(this.formData());
  }

}
