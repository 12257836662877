<div>
  <div class="table-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.625rem">
    <p>
      <button mat-raised-button color="primary" i18n (click)="openCreationDialog()">New user</button>
    </p>
    <p *ngIf="itemsPage$ | async as itemsPage; else loadingP" i18n>{itemsPage.totalElements || 0, plural,
      =0 {no users} =1 {1 user} other { {{itemsPage.totalElements}} users} } found</p>
    <ng-template #loadingP>
      <p fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
        <mat-spinner diameter="20"></mat-spinner><span i18n>Loading users...</span>
      </p>
    </ng-template>
    <div class="table-filter">
      <app-zone-filter [(zone)]="pageRequest.zone" (zoneChange)="resetPagination();"></app-zone-filter>
    </div>
  </div>
  <div>
    <table mat-table [dataSource]="(itemsPage$ | async)?.content">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef i18n>Name</th>
        <td mat-cell *matCellDef="let user"> <strong>{{user.lastName}}</strong> {{user.firstName}} </td>
      </ng-container>
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef i18n>Username</th>
        <td mat-cell *matCellDef="let user"> {{user.username}} </td>
      </ng-container>
      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef i18n>Email address</th>
        <td mat-cell *matCellDef="let user">{{user.emailAddress}}</td>
      </ng-container>
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef i18n>Phone number</th>
        <td mat-cell *matCellDef="let user">{{user.phoneNumber}}</td>
      </ng-container>
      <ng-container matColumnDef="zone">
        <th mat-header-cell *matHeaderCellDef i18n>Country</th>
        <td mat-cell *matCellDef="let user">{{user.zoneName | titlecase}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let user">
          <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-button color="primary" (click)="openUpdateDialog(user)" i18n>Edit</button>
            <button mat-button color="warn" i18n (click)="openDeletionDialog(user)">Delete</button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    </table>
  </div>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [length]="(itemsPage$ | async)?.totalElements" [pageIndex]="pageRequest.pageIndex" [pageSize]="pageRequest.pageSize" (page)="onPaginationChange($event);"></mat-paginator>
</div>
