import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';
import { CommonModule } from '../common/common.module';

import { AcrissChooserComponent } from './acriss/chooser';

@NgModule({
  imports: [
    CoreModule,
    CommonModule
  ],
  declarations: [
    AcrissChooserComponent
  ],
  entryComponents: [],
  exports: [
    AcrissChooserComponent
  ]
})
export class CarModelSearchModule { }
