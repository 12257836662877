<div>
  <mat-form-field>
    <mat-label i18n>Car maker</mat-label>
    <mat-select *ngIf="includeNull" [formControl]="carMaker">
      <mat-option i18n>&lt;All car makers&gt;</mat-option>
      <mat-option *ngFor="let item of carMakers | async" [value]="item.id">{{item.name | titlecase}}</mat-option>
    </mat-select>
    <mat-select *ngIf="!includeNull" [formControl]="carMaker" required>
      <mat-option *ngFor="let item of carMakers | async" [value]="item.id">{{item.name | titlecase}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
