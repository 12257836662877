import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {Picture} from '../../common';
import {PictureDeletionService} from './picture-deletion.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-picture-deletion',
  templateUrl: './deletion.component.html'
})
export class DeletionComponent implements OnInit {

  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public picture: Picture,
    private pictureDeletionService: PictureDeletionService
  ) { }

  ngOnInit() {
  }

  /** Executed when the user confirms the action */
  onAction() {
    this.actionState.register(this.pictureDeletionService.deletePicture(this.picture.id));
  }

}
