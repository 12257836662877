<h1 class mat-dialog-title i18n>Filters</h1>
<div class="mat-dialog-content">
  <form [formGroup]="cmpformGroup">

    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label i18n>Basics</ng-template>

        <app-zone-multichooser *appAdminConnected [zones]="cmpformGroup.controls.zones" [includeNull]="true"></app-zone-multichooser>
        <app-zone-multichooser *appInternalConnected [zones]="cmpformGroup.controls.zones" [includeNull]="true"></app-zone-multichooser>

        <app-carmaker-chooser [carMaker]="cmpformGroup.controls.carMaker" [includeNull]="true"></app-carmaker-chooser>

        <mat-form-field>
          <mat-label i18n>Car model ID</mat-label>
          <input matInput formControlName="modelId" maxlength="5" />
          <mat-error *ngIf="cmpformGroup.controls.modelId.errors" i18n>Model ID must not exceed 5 characters.</mat-error>
        </mat-form-field>

        <app-acriss-chooser [vehicleClasses]="cmpformGroup.controls.vehicleClasses" [acrissCode]="cmpformGroup.controls.acrissCode" [isVehicleClasses]="cmpformGroup.controls.isVehicleClasses"></app-acriss-chooser>

        <mat-form-field>
          <mat-label i18n>Ranks</mat-label>
          <mat-select multiple formControlName="modelRanks">
            <mat-option i18n>&lt;All ranks&gt;</mat-option>
            <mat-option *ngFor="let item of availableRanks" [value]="item">
              {{item}}</mat-option>
          </mat-select>
        </mat-form-field>

      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label i18n>Advanced</ng-template>

        <div class="mg-top">
          <mat-slide-toggle formControlName="isWithoutMainPicture" i18n>
            Only car models with no visuals
          </mat-slide-toggle>
        </div>
        <div class="mg-top">
          <mat-slide-toggle formControlName="isIncludeOnlyAvailable" i18n>
            Only car models available for export
          </mat-slide-toggle>
        </div>

        <mat-form-field>
          <mat-label i18n>Created on</mat-label>
          <input [matDatepicker]="sheetsCreatedOnDatepicker" matInput formControlName="createdOn" />
          <mat-datepicker-toggle matSuffix [for]="sheetsCreatedOnDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #sheetsCreatedOnDatepicker></mat-datepicker>
          <mat-error *ngIf="cmpformGroup.controls.createdOn.errors" i18n>
            Date format is invalid.</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Last modified on</mat-label>
          <input [matDatepicker]="sheetsLastModifiedOnDatepicker" matInput formControlName="lastModifiedOn" />
          <mat-datepicker-toggle matSuffix [for]="sheetsLastModifiedOnDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #sheetsLastModifiedOnDatepicker></mat-datepicker>
          <mat-error *ngIf="cmpformGroup.controls.lastModifiedOn.errors" i18n>
            Date format is invalid.</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Created after</mat-label>
          <input [matDatepicker]="sheetsCreatedAfterDatepicker" matInput formControlName="createdAfter" />
          <mat-datepicker-toggle matSuffix [for]="sheetsCreatedAfterDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #sheetsCreatedAfterDatepicker></mat-datepicker>
          <mat-error *ngIf="cmpformGroup.controls.createdAfter.errors" i18n>
            Date format is invalid.</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Last modified after</mat-label>
          <input [matDatepicker]="sheetsLastModifiedAfterDatepicker" matInput formControlName="lastModifiedAfter" />
          <mat-datepicker-toggle matSuffix [for]="sheetsLastModifiedAfterDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #sheetsLastModifiedAfterDatepicker></mat-datepicker>
          <mat-error *ngIf="cmpformGroup.controls.lastModifiedAfter.errors" i18n>
            Date format is invalid.</mat-error>
        </mat-form-field>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
    <button mat-raised-button color="primary" (click)="onSubmit()" i18n>Validate</button>
    <button mat-button mat-dialog-close i18n>Back</button>
  </div>
</mat-dialog-actions>
