import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from './material.module';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule
  ],
  exports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule
  ]
})
export class CoreModule { }
