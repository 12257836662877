import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';
import { CommonModule } from '../common/common.module';
import { CarModelSearchModule } from '../carmodel-search/carmodel-search.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ZoneModule } from '../zone/zone.module';
import { PictureModule } from '../picture/picture.module';

import { CarModelSearchRequestComponent } from './management/carmodel-search-request';
import { CarModelRetrievalComponent } from './management/retrieval';
import { CarModelCardComponent } from './management/retrieval/card';
import { CarModelRelatedPictureComponent } from './management/retrieval/card/relatedpicture';
import { CarModelUpdateComponent } from './management/update';
import { CarModelDeletionComponent } from './management/deletion';
import { CarModelPictureLinkUpdateComponent } from './management/picturelinkupdate';
import { CarModelPictureLinkRemovalComponent } from './management/picturelinkremoval';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    AuthenticationModule,
    ZoneModule,
    CarModelSearchModule,
    PictureModule
  ],
  declarations: [
    CarModelSearchRequestComponent,
    CarModelRetrievalComponent,
    CarModelRelatedPictureComponent,
    CarModelCardComponent,
    CarModelUpdateComponent,
    CarModelDeletionComponent,
    CarModelPictureLinkUpdateComponent,
    CarModelPictureLinkRemovalComponent
  ],
  entryComponents: [
    CarModelSearchRequestComponent,
    CarModelUpdateComponent,
    CarModelDeletionComponent,
    CarModelPictureLinkUpdateComponent,
    CarModelPictureLinkRemovalComponent
  ],
  exports: [
  ]
})
export class CarModelModule { }
