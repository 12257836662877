import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppUser } from '../../common';

@Injectable({
  providedIn: 'root'
})
export class UserUpdateService {

  constructor(private http: HttpClient) { }

  /**
   * @param user Data for the user to be updated
   * @return An observable of the response
   */
  update(user: AppUser) {
    return this.http.put<any>('/api/admin/users', user);
  }
}
