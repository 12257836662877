import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';
import { AuthenticationModule } from '../authentication/authentication.module';

import { PictureUploadComponent } from './upload';
import { PictureImageComponent } from './image';
import { PictureGalleryComponent } from './gallery';
import { PictureCardComponent } from './gallery/card';
import { PictureRetrievalComponent } from './management/retrieval';
import { PictureDeletionComponent } from './management/deletion';

@NgModule({
  imports: [
    CoreModule,
    AuthenticationModule
  ],
  declarations: [
    PictureUploadComponent,
    PictureImageComponent,
    PictureGalleryComponent,
    PictureCardComponent,
    PictureRetrievalComponent,
    PictureDeletionComponent
  ],
  exports: [
    PictureImageComponent,
    PictureUploadComponent,
    PictureGalleryComponent,
    PictureCardComponent
  ],
  entryComponents: [PictureDeletionComponent]
})
export class PictureModule { }
