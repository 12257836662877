<div class="card-picture" fxLayout="row" fxLayoutAlign="start center">
  <small>
    <ng-content></ng-content>
  </small>
  <div class="card-picture-image">
    <app-picture-image [uuid]="value" height="7rem"></app-picture-image>
  </div>

  <div fxLayout="column">
    <button mat-icon-button color="primary" aria-label="Add/change visual" i18n-title title="Add/change visual" (click)="openUpdatePictureLinkDialog()">
      <mat-icon>add_circle</mat-icon>
    </button>
    <button [disabled]="!value" mat-icon-button color="warn" aria-label="Remove visual" i18n-title title="Remove visual" (click)="openRemovePictureLinkDialog()">
      <mat-icon>remove_circle</mat-icon>
    </button>
  </div>

</div>
