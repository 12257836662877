<h1 class mat-dialog-title>
  <span *ngIf="executedOnce; else periodicExportTaskTitle" i18n>One-time export task update</span>
  <ng-template #periodicExportTaskTitle i18n>Periodic export task update</ng-template>
</h1>
<h4 *ngIf="actionState.success" class="success" i18n>Success</h4>
<h4 *ngIf="actionState.error" class="warn" i18n><strong>Error</strong></h4>
<div mat-dialog-content>

  <app-exporttask-edition *ngIf="actionState.initial" [cmpformGroup]="cmpformGroup" [executedOnce]="original.executedOnce"></app-exporttask-edition>

  <span *ngIf="actionState.success" i18n>The export task was successfully updated.</span>
  <span *ngIf="actionState.error" class="warn" i18n>The export task could not be updated.</span>
</div>
<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
    <button *ngIf="actionState.initial" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="cmpformGroup.invalid" i18n>Update</button>
    <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
    <button mat-button mat-dialog-close i18n>Back to export tasks</button>
  </div>
</mat-dialog-actions>
