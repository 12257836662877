/** A page of items */
export class Page<T> {
  /** The total amount of elements */
  public totalElements: number = 0;
  /** The number of total pages */
  public totalPages: number = 0;
  /** The number of elements currently on this page */
  public numberOfElements: number = 0;
  /** The size of the page */
  public size: number = 0;
  /** The number of the current page */
  public number: number = 0;
  /** The page content */
  public content: Array<T> = [];
}
