import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserDeletionService {

  constructor(private http: HttpClient) { }

  /**
   * @param userId ID of the user to be deleted
   * @return An observable of the response
   */
  deleteUser(userId: number) {
    return this.http.delete<any>(`/api/admin/users/${userId}`);
  }
}
