<div class="flippable-card" [ngClass]="{'flipped': flipped}" fxLayout="row" fxLayoutAlign="start stretch">
  <mat-card class="front">
    <mat-card-header>
      <div *ngIf=" !carModel.exportConfig.available || !carModel.relatedPictures?.picture1" class="card-danger"
           mat-card-avatar>!
      </div>
      <mat-card-title>{{carModel.description.label}}</mat-card-title>
      <mat-card-subtitle><strong *ngIf="!carModel.exportConfig.available" class="warn" i18n>unavailable&nbsp;</strong>
        <strong *ngIf="!carModel.relatedPictures?.picture1" class="warn">
          <span *ngIf="!carModel.exportConfig.available">/&nbsp;</span>
          <span i18n>no visual</span></strong>
      </mat-card-subtitle>
    </mat-card-header>
    <app-picture-image mat-card-image [uuid]="carModel.relatedPictures?.picture1" width="18.75rem"></app-picture-image>
    <mat-card-content class="no-margin">
      <dl>
        <dt class="row" i18n>Online vehicle name</dt>
        <dd class="row">{{carModel.description.alternativeLabel || '--'}}</dd>
      </dl>
      <div class="dl-columns-2">
        <dl>
          <dt i18n>Country</dt>
          <dd>{{carModel.zoneName | titlecase}}</dd>
        </dl>
        <dl>
          <dt i18n>Maker</dt>
          <dd>{{carModel.description.carMakerName | titlecase}}</dd>
        </dl>
      </div>
      <div class="dl-columns-2">
        <dl>
          <dt i18n>Car model ID</dt>
          <dd>{{carModel.modelId}}</dd>
        </dl>
        <dl>
          <dt i18n>Creation date</dt>
          <dd>{{carModel.creationDate | date}}</dd>
        </dl>
      </div>
      <div class="dl-columns-3">
        <dl>
          <dt i18n>ACRISS code</dt>
          <dd>{{carModel.description.acriss}}</dd>
        </dl>
        <dl>
          <dt i18n>Rank</dt>
          <dd>{{carModel.description.modelRank || '--'}}</dd>
        </dl>
        <dl>
          <dt i18n>Car category</dt>
          <dd>{{carModel.description.category || '--'}}</dd>
        </dl>
      </div>
      <div class="dl-columns-3">
        <dl>
          <dt i18n>Seats</dt>
          <dd>{{carModel.description.seats || '--'}}</dd>
        </dl>
        <dl>
          <dt i18n>Doors</dt>
          <dd>{{carModel.description.doors || '--'}}</dd>
        </dl>
        <dl>
          <dt i18n>Boot capacity</dt>
          <dd>{{carModel.description.bootCapacity || '--'}}</dd>
        </dl>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <button mat-button color="primary" i18n (click)="flipped = !flipped">Edit visuals</button>
      <button *appAdminConnected mat-button color="warn" i18n (click)="openDeletionDialog()">Delete</button>
    </mat-card-footer>
  </mat-card>
  <mat-card class="back">

    <mat-card-content class="no-margin">
      <div fxLayout="column" fxLayoutGap="0">
        <div class="text-center"><strong>{{carModel.description.label}}</strong></div>
        <app-carmodel-relatedpicture (onUpdate)="carModel.relatedPictures.picture1 = $event" [carModelId]="carModel.id"
                                     [carModelLabel]="carModel.tags"
                                     [value]="carModel.relatedPictures.picture1" type="PICTURE_1">
          <span i18n>Vehicle pointing right</span>
        </app-carmodel-relatedpicture>

        <app-carmodel-relatedpicture (onUpdate)="carModel.relatedPictures.picture2 = $event" [carModelId]="carModel.id"
                                     [carModelLabel]="carModel.tags"
                                     [value]="carModel.relatedPictures.picture2" type="PICTURE_2">
          <span i18n>Vehicle pointing left</span>
        </app-carmodel-relatedpicture>

        <app-carmodel-relatedpicture (onUpdate)="carModel.relatedPictures.picture3 = $event" [carModelId]="carModel.id"
                                     [carModelLabel]="carModel.tags"
                                     [value]="carModel.relatedPictures.picture3" type="PICTURE_3">
          <span i18n>Picture 3</span>
        </app-carmodel-relatedpicture>

        <app-carmodel-relatedpicture (onUpdate)="carModel.relatedPictures.picture4 = $event" [carModelId]="carModel.id"
                                     [carModelLabel]="carModel.tags"
                                     [value]="carModel.relatedPictures.picture4" type="PICTURE_4">
          <span i18n>Picture 4</span>
        </app-carmodel-relatedpicture>
      </div>

    </mat-card-content>
    <mat-card-footer>
      <button mat-button color="primary" aria-label="Back" i18n-title title="Back" (click)="flipped = !flipped">
        <mat-icon>arrow_back</mat-icon>
        <span i18n>Back</span>
      </button>
    </mat-card-footer>
  </mat-card>
</div>
