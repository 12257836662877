<form [formGroup]="cmpformGroup">
  <div
    [class.search-tags]="!chooserMode"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="0.25rem"
  >
    <mat-form-field appearance="fill" fxFlex>
      <input
        matInput
        formControlName="tags"
        i18n-placeholder
        placeholder="Search by car maker, car model, ..."
      />
      <button
        mat-icon-button
        matSuffix
        i18n-aria-label
        aria-label="Search"
        (click)="onSubmit()"
      >
        <mat-icon class="primary">search</mat-icon>
      </button>
      <mat-hint
      ><span i18n>Most recent visuals are displayed first.</span></mat-hint
      >
    </mat-form-field>
  </div>
</form>
<div [class.search-result]="!chooserMode">
  <ng-container *ngIf="items$ | async as items; else loadingP">
    <p i18n>
      {items.length || 0, plural, =0 {no visuals} =1 {1 visual} other {
      {{ totalLength }} visuals} } found
    </p>
    <div
      class="small-cards"
      fxLayout="row wrap"
      fxLayoutGap="1rem"
      fxLayoutAlign="flex-start stretch"
    >
      <ng-container *ngFor="let item of items">
        <app-picture-card
          [chooserMode]="chooserMode"
          [picture]="item"
          [selected]="uuid === item.uuid"
          (onSelected)="notifySelection(item)"
          (onDeleted)="notifyDeletion(item)"
        ></app-picture-card>
      </ng-container>
    </div>
  </ng-container>

  <mat-paginator
    (page)="onPaginationChange($event)"
    [length]="totalLength"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    showFirstLastButtons
  ></mat-paginator>

  <ng-template #loadingP>
    <p fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
      <mat-spinner diameter="20"></mat-spinner
      >
      <span i18n>Loading visuals...</span>
    </p>
  </ng-template>
</div>
