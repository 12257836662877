import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

import {Page} from '../../../common';

import {ExportTask} from '../../common';
import {ExportTaskRetrievalService} from './exporttask-retrieval.service';
import {ExportTaskRetrievalRequest} from './exporttask-retrieval-request';

import {ExportTaskCreationComponent} from '../creation';
import {ExportTaskUpdateComponent} from '../update';
import {ExportTaskDeletionComponent} from '../deletion';
import {ExportTaskActivationComponent} from '../activation';

/** A component used to display export tasks */
@Component({
  selector: 'app-exporttask-retrieval',
  templateUrl: './retrieval.component.html',
  styleUrls: ['./retrieval.component.scss']
})
export class RetrievalComponent implements OnInit {

  /** Whether this component should display one-time export tasks or periodic export tasks */
  @Input() executedOnce: boolean;
  pageRequest = { pageIndex: 0, pageSize: 50 };
  /** Page of items to be displayed */
  itemsPage$: Observable<Page<ExportTask>>;

  columnsToDisplay = ['name', 'state', 'execution', 'actions'];
  pageSizeOptions = [20, 50, 100, 500];

  constructor(
    private exportTaskRetrievalService: ExportTaskRetrievalService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchExportTasks();
  }

  /** Called when pagination data changes */
  onPaginationChange($event: PageEvent) {
    this.pageRequest.pageIndex = $event.pageIndex;
    this.pageRequest.pageSize = $event.pageSize;
    this.fetchExportTasks();
  }

  /** Fetches a page of export tasks, if necessary */
  fetchExportTasks() {
    this.itemsPage$ = this.exportTaskRetrievalService.retrieveAll(
      new ExportTaskRetrievalRequest(this.executedOnce), this.pageRequest.pageSize, this.pageRequest.pageIndex);
  }

  /** Opens the creation dialog */
  openCreationDialog(): void {
    const dialogRef = this.dialog.open(ExportTaskCreationComponent, {
      width: '35rem',
      data: this.executedOnce
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fetchExportTasks();
    }, error => {
      this.fetchExportTasks();
    });
  }

  /** Opens the update dialog for the passed export task */
  openUpdateDialog(exportTask: ExportTask): void {
    const dialogRef = this.dialog.open(ExportTaskUpdateComponent, {
      width: '35rem',
      data: exportTask
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fetchExportTasks();
    }, error => {
      this.fetchExportTasks();
    });
  }

  /** Opens the deletion dialog for the passed export task */
  openDeletionDialog(exportTask: ExportTask): void {
    const dialogRef = this.dialog.open(ExportTaskDeletionComponent, {
      width: '28rem',
      data: exportTask
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fetchExportTasks();
    }, error => {
      this.fetchExportTasks();
    });
  }

  /** Opens the activation dialog for the passed export task */
  openActivationDialog(exportTask: ExportTask): void {
    const dialogRef = this.dialog.open(ExportTaskActivationComponent, {
      width: '28rem',
      data: exportTask
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fetchExportTasks();
    }, error => {
      this.fetchExportTasks();
    });
  }

}
