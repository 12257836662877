import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

/** A component used to display and edit partner data */
@Component({
  selector: 'partner-management',
  templateUrl: './management.component.html'
})
export class ManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
