import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

import {Page} from '../../../common';

import {CarModel} from '../../common';
import {CarModelRetrievalService} from './carmodel-retrieval.service';
import {CarModelSearchRequestComponent} from '../carmodel-search-request';
import {CarModelSearchRequest} from '../../../carmodel-search/carmodel-search-request';

/** A component used to search and display car models */
@Component({
  selector: 'app-carmodel-retrieval',
  templateUrl: './retrieval.component.html',
  styleUrls: ['./retrieval.component.scss']
})
export class RetrievalComponent implements OnInit {

  private previousPageRequest = null;
  pageSizeOptions = [20, 50, 100, 200];

  searchRequest: CarModelSearchRequest =
    new CarModelSearchRequest(null, null, null, null, null, null, null, null, [], [], [], false, false);

  pageRequest = { pageIndex: 0, pageSize: 20 };
  /** Page of items to be displayed */
  itemsPage$: Observable<Page<CarModel>>;

  constructor(
    private carModelRetrievalService: CarModelRetrievalService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchCarModels();
  }

  /** Called when the zone filter changes */
  resetPagination() {
    this.pageRequest.pageIndex = 0;
    this.fetchCarModels();
  }

  /** Called when pagination data changes */
  onPaginationChange($event: PageEvent) {
    this.pageRequest.pageIndex = $event.pageIndex;
    this.pageRequest.pageSize = $event.pageSize;
    this.fetchCarModels();
  }

  /**  Fetches a page of car models with no condition */
  forceFetchCarModels() {
    this.itemsPage$ = this.carModelRetrievalService.retrieveAll(
      this.searchRequest, this.pageRequest.pageSize, this.pageRequest.pageIndex);
  }

  /** @return Whether the current page request has been modified since the last request */
  isPageRequestChanged() {
    return !this.previousPageRequest ||
      this.previousPageRequest.pageIndex !== this.pageRequest.pageIndex ||
      this.previousPageRequest.pageSize !== this.pageRequest.pageSize;
  }

  /** Fetches a page of car models, if necessary */
  fetchCarModels() {
    if (this.isPageRequestChanged()) {
      this.forceFetchCarModels();
      this.previousPageRequest = { ...this.pageRequest };
    }
  }

  /** Opens the dialog to update filters */
  openFiltersDialog(): void {
    const dialogRef = this.dialog.open(CarModelSearchRequestComponent, {
      width: '28rem',
      data: this.searchRequest
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.searchRequest = result;
        this.pageRequest.pageIndex = 0;
        this.forceFetchCarModels();
      }
    }, error => { });
  }

}
