<div id="user-newpassword-box" class="open-page" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title i18n>New password</mat-card-title>
    <mat-card-subtitle i18n>
      <span *ngIf="actionState.initial">Please, fill in your username.</span>
      <strong *ngIf="actionState.success" class="success">Success</strong>
      <strong *ngIf="actionState.error" class="warn">Error</strong>
    </mat-card-subtitle>
    <mat-card-content fxLayout="column">
      <form *ngIf="actionState.initial" [formGroup]="cmpformGroup">
        <p>
          <mat-form-field>
            <mat-label i18n>Username</mat-label>
            <input matInput formControlName="username" required />
            <mat-error *ngIf="f.username.errors" i18n>Username is required.</mat-error>
          </mat-form-field>
        </p>
      </form>
      <p *ngIf="actionState.success" i18n>An email has been sent to you.<br />
        Please, follow the instructions in the email to update your password.</p>
      <p *ngIf="actionState.error" class="warn" i18n>No account was found for the username
        or an unexpected error occured.<br />Please, check your username or try again later.</p>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
        <button *ngIf="actionState.initial" mat-raised-button color="primary" [disabled]="cmpformGroup.invalid || actionState.loading" (click)="onSubmit()" i18n>Request new password</button>
        <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
        <button mat-button routerLink="/user/self/login" i18n>Back to login page</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
