import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PictureUploadService {

  constructor(private http: HttpClient) { }

  /** @param data Data to be uploaded
   * @return An observable of the response */
  uploadPicture(data: FormData) {
    return this.http.post<any>(`/api/connected/pictures`, data);
  }
}
