import { UserProfile } from './user-profile';

/** A user */
export class AppUser {
  static USERNAME_PATTERN = '^[0-9a-zA-Z_\\-/]{3,32}$';
  public id: number;
  public version: number;
  public lastName: string;
  public firstName: string;
  public username: string;
  public emailAddress: string;
  public phoneNumber: string;
  public zone: string;
  public profile: UserProfile;
  public department: string;
}
