import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {AppUser, UserProfile} from '../../common';
import {UserUpdateService} from './user-update.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-user-update',
  templateUrl: './update.component.html'
})
export class UpdateComponent implements OnInit {

  userProfiles = UserProfile;
  profile: UserProfile;
  cmpformGroup: FormGroup;
  zone: string;
  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public currentUser: AppUser,
    private formBuilder: FormBuilder,
    private userUpdateService: UserUpdateService
  ) {
    this.zone = this.currentUser.zone;
    this.profile = (<any>UserProfile)[this.currentUser.profile];
  }

  ngOnInit() {
    const required256 = Validators.compose([Validators.required, Validators.maxLength(256)]);
    const optional256 = Validators.maxLength(256);
    this.cmpformGroup = this.formBuilder.group({
      lastName: [this.currentUser.lastName, required256],
      firstName: [this.currentUser.firstName, required256],
      username: [this.currentUser.username, Validators.compose([Validators.required, Validators.pattern(AppUser.USERNAME_PATTERN)])],
      emailAddress: [this.currentUser.emailAddress, Validators.compose([Validators.required, Validators.email, Validators.maxLength(128)])],
      phoneNumber: [this.currentUser.phoneNumber, Validators.maxLength(20)],
      department: [this.currentUser.department, optional256],
      zone: [this.currentUser.zone, Validators.required]
    });
  }

  /** Convenience getter for easy access to form fields */
  get f() { return this.cmpformGroup.controls; }

  /** @return All data stored in the form */
  private formData() {
    const user = new AppUser();
    user.id = this.currentUser.id;
    user.version = this.currentUser.version;
    user.lastName = this.f.lastName.value;
    user.firstName = this.f.firstName.value;
    user.username = this.f.username.value;
    user.emailAddress = this.f.emailAddress.value;
    user.phoneNumber = this.f.phoneNumber.value;
    user.department = this.f.department.value;
    user.zone = this.f.zone.value;
    user.profile = this.currentUser.profile;
    return user;
  }

  /** Whether a validation error occured */
  get validationError() {
    return this.actionState.error && this.actionState.errorCode === 409;
  }

  /** Executed when the user confirms the action */
  onSubmit() {
    this.actionState.register(this.userUpdateService.update(this.formData()));
  }

}
