<div id="user-registration-box" class="open-page" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title i18n>Sign up</mat-card-title>
    <mat-card-subtitle i18n>
      <span *ngIf="actionState.initial">Sign up to access the application.</span>
      <strong *ngIf="actionState.success" class="success">Success</strong>
      <strong *ngIf="actionState.error" class="warn">Error</strong>
    </mat-card-subtitle>
    <mat-card-content fxLayout="column">
      <mat-vertical-stepper *ngIf="actionState.initial" linear="true">
        <mat-step [stepControl]="formGroup1">
          <form [formGroup]="formGroup1">
            <ng-template matStepLabel><span i18n>Your information</span></ng-template>
            <mat-form-field>
              <mat-label i18n>First name</mat-label>
              <input formControlName="firstName" matInput required/>
              <mat-error *ngIf="formGroup1.controls.firstName.errors" i18n>First name is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Last name</mat-label>
              <input formControlName="lastName" matInput required/>
              <mat-error *ngIf="formGroup1.controls.lastName.errors" i18n>Last name is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Email address</mat-label>
              <input formControlName="emailAddress" matInput required/>
              <mat-error *ngIf="formGroup1.controls.emailAddress.errors" i18n>Email address is required and must be a
                valid email address.
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Phone number</mat-label>
              <input formControlName="phoneNumber" matInput/>
              <mat-hint align="start" i18n>Must start with the country code</mat-hint>
              <mat-error *ngIf="formGroup1.controls.phoneNumber.errors" i18n>Must contain 20 characters maximum
              </mat-error>
            </mat-form-field>
            <div class="stepper-actions">
              <button mat-flat-button color="primary" matStepperNext i18n>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="formGroup2">
          <form [formGroup]="formGroup2">
            <ng-template matStepLabel><span i18n>Your company</span></ng-template>
            <mat-form-field>
              <mat-label i18n>Company</mat-label>
              <input formControlName="company" matInput required/>
              <mat-error *ngIf="formGroup2.controls.company.errors" i18n>Company is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Department</mat-label>
              <input formControlName="department" matInput required/>
              <mat-error *ngIf="formGroup2.controls.department.errors" i18n>Department is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Position</mat-label>
              <input formControlName="position" matInput/>
              <mat-error *ngIf="formGroup2.controls.position.errors" i18n>Wrong format.</mat-error>
            </mat-form-field>
            <div class="stepper-actions">
              <button mat-flat-button matStepperPrevious i18n>Back</button>
              <button mat-flat-button color="primary" matStepperNext i18n>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="formGroup3">
          <form [formGroup]="formGroup3">
            <ng-template matStepLabel><span i18n>Your company's address</span></ng-template>
            <mat-form-field>
              <mat-label i18n>Address</mat-label>
              <input formControlName="address1" matInput/>
              <mat-error *ngIf="formGroup3.controls.address1.errors" i18n>Wrong format.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Address complement</mat-label>
              <input formControlName="address2" matInput/>
              <mat-error *ngIf="formGroup3.controls.address2.errors" i18n>Wrong format.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Postal code</mat-label>
              <input formControlName="postalCode" matInput/>
              <mat-error *ngIf="formGroup3.controls.postalCode.errors" i18n>The postal code cannot exceed 10
                characters.
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>City</mat-label>
              <input formControlName="city" matInput/>
              <mat-error *ngIf="formGroup3.controls.city.errors" i18n>Wrong format.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n>Country</mat-label>
              <input formControlName="country" matInput required/>
              <mat-error *ngIf="formGroup3.controls.country.errors" i18n>Country is required.</mat-error>
            </mat-form-field>
            <div class="stepper-actions">
              <button mat-flat-button matStepperPrevious i18n>Back</button>
              <button mat-flat-button color="primary" matStepperNext i18n>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel><span i18n>Send request</span></ng-template>
          <p i18n>You provided all information needed for your sign up request.</p>
          <p>
            <mat-checkbox (change)="gdprAccepted = !gdprAccepted" [checked]="gdprAccepted" i18n>By signing up, you agree
              to share your personal data: first name, last name and email address
            </mat-checkbox>
          </p>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
            <button (click)="onSubmit()" [disabled]="!gdprAccepted" color="primary"
                    i18n mat-raised-button>Send sign up request
            </button>
            <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
          </div>
        </mat-step>
      </mat-vertical-stepper>
      <p *ngIf="actionState.success" i18n>Your sign up request has been sent.<br/>
        You will shortly be contacted by Europcar Mobility Group.</p>
      <p *ngIf="actionState.error" class="warn" i18n>Your sign up request could not be sent.<br/>
        Please, try again later.</p>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
        <button mat-button routerLink="/user/self/login" i18n>Back to login page</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
