import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ActionState} from '../../../common';

import {PasswordService} from './password.service';
import {WithPassword} from './with-password';


@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  cmpformGroup: FormGroup;
  actionState: ActionState = new ActionState();

  constructor(
    private formBuilder: FormBuilder,
    private passwordService: PasswordService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    const passwordValidator = Validators.compose([Validators.required, Validators.pattern('^[0-9a-zA-Z_\\-!:;,@\\[\\]\\(\\)\\=#~%]{8,30}$')]);
    this.cmpformGroup = this.formBuilder.group({
      password: ['', passwordValidator],
      passwordConfirmation: ['', passwordValidator],
    });
  }

  /** Convenience getter for easy access to form fields */
  get f() { return this.cmpformGroup.controls; }

  /** @return Whether the form can be submitted */
  get noMatchForPasswords() { return this.cmpformGroup.invalid || this.f.password.value !== this.f.passwordConfirmation.value; }

  /** @return All data stored in the form */
  private formData() { return new WithPassword(this.f.password.value); }

  /** @return The user token */
  private userToken() {
    return (this.route.snapshot && this.route.snapshot.queryParams ?
      this.route.snapshot.queryParams.token : undefined) || '';
  }

  /** On form submission */
  onSubmit() {
    this.actionState.register(this.passwordService.update(this.formData(), this.userToken()));
  }

}
