<div *ngIf="partners$ | async as partners">
  <mat-form-field>
    <mat-label i18n>Partner</mat-label>
    <mat-select [formControl]="partnerId" required>
      <mat-option i18n>&lt;None&gt;</mat-option>
      <mat-option *ngFor="let item of partners.content" [value]="item.id">{{item.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="partnerId.errors" i18n>Partner is required.</mat-error>
  </mat-form-field>
</div>
