<div>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label i18n>Users</ng-template>
      <ng-template matTabContent>
        <app-user-retrieval [profile]="userProfiles.USER"></app-user-retrieval>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label i18n>Europcar Mobility Group users</ng-template>
      <ng-template matTabContent>
        <app-user-retrieval [profile]="userProfiles.ECI"></app-user-retrieval>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label i18n>Administrators</ng-template>
      <ng-template matTabContent>
        <app-user-retrieval [profile]="userProfiles.ADMIN"></app-user-retrieval>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
