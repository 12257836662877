import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {CarModel} from '../../common';
import {CarModelDeletionService} from './carmodel-deletion.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-carmodel-deletion',
  templateUrl: './deletion.component.html'
})
export class DeletionComponent implements OnInit {

  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public carModel: CarModel,
    private carModelDeletionService: CarModelDeletionService
  ) { }

  ngOnInit() {
  }

  /** Executed when the user confirms the action */
  onAction() {
    this.actionState.register(this.carModelDeletionService.deleteCarModel(this.carModel.id));
  }

}
