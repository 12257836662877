import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { LightPartner } from '../light-partner';
import { CommonPartnerRetrievalService } from '../partner-retrieval.service';

@Component({
  selector: 'app-partner-chooser',
  templateUrl: './chooser.component.html'
})
export class ChooserComponent implements OnInit {

  @Input('partnerId')
  public partnerId: FormControl;
  partners$: Observable<LightPartner[]>;

  constructor(
    private partnerRetrievalService: CommonPartnerRetrievalService
  ) { }

  ngOnInit() {
    this.partners$ = this.partnerRetrievalService.retrieveAll();
  }
}
