<div class="mg-top">
  <mat-slide-toggle [formControl]="isVehicleClasses" i18n>
    Filter on vehicle classes, not on ACRISS code
  </mat-slide-toggle>
</div>
<mat-form-field *ngIf="isVehicleClasses.value === true; else acrissCodeBlock">
  <mat-label i18n>Vehicle classes</mat-label>
  <mat-select multiple [formControl]="vehicleClasses">
    <mat-option i18n>&lt;All vehicle classes&gt;</mat-option>
    <mat-option *ngFor="let item of availableVehicleClasses" [value]="item.value">
      {{item.label}}</mat-option>
  </mat-select>
</mat-form-field>
<ng-template #acrissCodeBlock>
  <mat-form-field>
    <mat-label i18n>ACRISS code</mat-label>
    <input matInput [formControl]="acrissCode" maxlength="5"/>
    <mat-hint i18n>A full ACRISS code or the first 2 or 3 characters of an ACRISS code can be used.</mat-hint>
    <mat-error *ngIf="acrissCode.errors" i18n>ACRISS code must not exceed 5 characters.</mat-error>
  </mat-form-field>
</ng-template>
