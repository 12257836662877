<h1 class mat-dialog-title i18n>Partner update</h1>
<h4 *ngIf="actionState.initial">{{currentPartner.name}}</h4>
<h4 *ngIf="actionState.success" class="success" i18n>Success</h4>
<h4 *ngIf="actionState.error" class="warn" i18n><strong>Error</strong></h4>
<div mat-dialog-content>
  <form *ngIf="actionState.initial" [formGroup]="cmpformGroup">
    <mat-form-field>
      <mat-label i18n>Use type</mat-label>
      <mat-select formControlName="carModelType" required>
        <mat-option *ngFor="let type of carModelTypes" [value]="type.value">{{type.label}}</mat-option>
      </mat-select>
      <mat-error *ngIf="cmpformGroup.controls.carModelType.errors" i18n>Use type is required.</mat-error>
    </mat-form-field>
  </form>
  <span *ngIf="actionState.success" i18n>The partner was successfully updated.</span>
  <span *ngIf="actionState.error" class="warn" i18n>The partner could not be updated.<br />Please, try again later.</span>
</div>
<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
    <button *ngIf="actionState.initial" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="cmpformGroup.invalid" i18n>Update</button>
    <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
    <button mat-button mat-dialog-close i18n>Back to partners</button>
  </div>
</mat-dialog-actions>
