import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewPasswordService } from './newpassword.service';
import { ActionState } from '../../../common';

@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.scss']
})
export class NewPasswordComponent implements OnInit {

  cmpformGroup: FormGroup;
  actionState: ActionState = new ActionState();

  constructor(
    private formBuilder: FormBuilder,
    private newPasswordService: NewPasswordService,
  ) {
  }

  ngOnInit() {
    this.cmpformGroup = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  /** Convenience getter for easy access to form fields */
  get f() { return this.cmpformGroup.controls; }

  /** @return All data stored in the form */
  formData() { return this.f.username.value; }

  /** On form submission */
  onSubmit() {
    this.actionState.register(this.newPasswordService.request(this.formData()));
  }
}
