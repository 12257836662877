/** Picture configuration of an export task */
export class PictureConfig {

  public static availablePictureFormats = ['JPG', 'PNG'];
  public static availablePictureTypes = ['MAIN', 'ALL'];

  constructor(public pictureSize: string,
    public pictureFormat: string,
    public pictureType: string) { }
}
