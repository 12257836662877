import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ExportTask} from '../../common';
import {ExportTaskActivationService} from './exporttask-activation.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-exporttask-activation',
  templateUrl: './activation.component.html'
})
export class ActivationComponent implements OnInit {

  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public exportTask: ExportTask,
    private exportTaskActivationService: ExportTaskActivationService
  ) { }

  ngOnInit() {
  }

  /** Executed when the user confirms the action */
  onAction() {
    this.actionState.register(this.exportTaskActivationService.activateExportTask(this.exportTask.id, !this.exportTask.config.active));
  }

  get actionType() {
    return this.exportTask.config.active ? 'deactivation' : 'activation';
  }

}
