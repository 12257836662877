import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewPasswordService {

  constructor(private http: HttpClient) { }


  /** Request a password update
   * @param request Name of the user
   * @return The response
   */
  request(username: string) {
    const escapedUsername = encodeURIComponent(username);
    return this.http.post<any>(`/api/open/users/newpassword?username=${escapedUsername}`, '');
  }
}
