<ng-container *ngIf="uuid; else noImg">

  <span *ngIf="pictureConfigService.pictureConfigValue; else loadingImg" fxLayout="row" fxLayoutAlign="start start"
  class="openable">
    <img [src]="pictureConfigService.pictureConfigValue.picturesRepositoryUrl + thumbnailUuid" i18n-alt alt="Car model picture" [ngStyle]="{'width': width, 'height': height}" [class.clickable]="clickable" (click)="notifyClick()">
    <a mat-icon-button color="light" [href]="pictureConfigService.pictureConfigValue.picturesRepositoryUrl + uuid" target="_new" rel="noopener noreferrer" i18n-title title="Open in new tab" i18n-aria-label aria-label="Open in new tab">
      <mat-icon>open_in_new</mat-icon>
    </a>
  </span>
  <ng-template #loadingImg>
    <img src="/assets/neutral_picture.png" i18n-alt alt="Loading picture" [ngStyle]="{'width': width, 'height': height}">
    <div class="image-caption" i18n>Loading...</div>
  </ng-template>
</ng-container>
<ng-template #noImg>
  <img src="/assets/no_picture.png" i18n-alt alt="No picture available" [ngStyle]="{'width': width, 'height': height}">
</ng-template>
