<h1 class mat-dialog-title>
  <span *ngIf="profile === userProfiles.USER" i18n>User creation</span>
  <span *ngIf="profile === userProfiles.ECI" i18n>Europcar Mobility Group user creation</span>
  <span *ngIf="profile === userProfiles.ADMIN" i18n>Administrator creation</span>
</h1>
<h4 *ngIf="actionState.success" class="success" i18n>Success</h4>
<h4 *ngIf="actionState.error" class="warn" i18n><strong>Error</strong></h4>
<div mat-dialog-content>
  <form [formGroup]="cmpformGroup">
    <p>
      <mat-form-field>
        <mat-label i18n>Username</mat-label>
        <input matInput formControlName="username" required />
        <mat-error *ngIf="cmpformGroup.controls.username.errors" i18n>Username is required and must match the expected format:
          3 to 32 characters (digits, letters, _, - and /)</mat-error>
      </mat-form-field>
    </p>
    <mat-form-field>
      <mat-label i18n>First name</mat-label>
      <input matInput formControlName="firstName" required />
      <mat-error *ngIf="cmpformGroup.controls.firstName.errors" i18n>First name is required.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Last name</mat-label>
      <input matInput formControlName="lastName" required />
      <mat-error *ngIf="cmpformGroup.controls.lastName.errors" i18n>Last name is required.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Email address</mat-label>
      <input matInput formControlName="emailAddress" required />
      <mat-error *ngIf="cmpformGroup.controls.emailAddress.errors" i18n>Email address is required and must be a valid email address.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Phone number</mat-label>
      <input matInput formControlName="phoneNumber" />
      <mat-hint align="start" i18n>Must start with the country code</mat-hint>
      <mat-error *ngIf="cmpformGroup.controls.phoneNumber.errors" i18n>Must contain 20 characters maximum</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n>Department</mat-label>
      <input matInput formControlName="department" />
      <mat-error *ngIf="cmpformGroup.controls.department.errors" i18n>Department must contain less than 256 characters.</mat-error>
    </mat-form-field>
    <app-zone-chooser [zone]="cmpformGroup.controls.zone"></app-zone-chooser>
  </form>
  <span *ngIf="actionState.success" i18n>The user was successfully created.</span>
  <span *ngIf="validationError" class="warn" i18n>The username defined for this user already exists.<br />Please, choose an other one.</span>
  <span *ngIf="actionState.error && !validationError" class="warn" i18n>The user could not be created.</span>
</div>
<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
    <button *ngIf="actionState.initial || validationError" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="cmpformGroup.invalid" i18n>Create</button>
    <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
    <button mat-button mat-dialog-close i18n>Back to users</button>
  </div>
</mat-dialog-actions>
