import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

/** Guard to route only if a user is ECI or administrator */
@Injectable({
  providedIn: 'root'
})
export class InternalAuthenticationGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  /** Checks if the current route can be activated */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let result = true;
    const token = this.authenticationService.tokenValue;
    if (!token.connected || (!token.isAdmin && !token.isInternal)) {
      result = false;
      this.router.navigate(['/user/self/login'], { queryParams: { returnUrl: state.url } });
    }
    return result;
  }
}
