import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { Zone } from '../zone';
import { ZoneService } from '../zone.service';

@Component({
  selector: 'app-zone-filter',
  templateUrl: './filter.component.html'
})
export class FilterComponent implements OnInit {

  zoneValue: string = null;
  @Output() zoneChange = new EventEmitter();
  zones: Observable<Zone[]>;

  constructor(
    private zoneService: ZoneService

  ) { }

  ngOnInit() {
    this.zones = this.zoneService.retrieveAll();
  }

  @Input()
  get zone() {
    return this.zoneValue;
  }

  set zone(value) {
    this.zoneValue = value;
    this.zoneChange.emit(this.zoneValue);
  }
}
