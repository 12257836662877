import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ZoneModule } from '../zone/zone.module';

import { PartnerRetrievalComponent } from './admin/retrieval';
import { PartnerUpdateComponent } from './admin/update';
import { PartnerManagementComponent } from './admin/management';

@NgModule({
  imports: [
    CoreModule,
    AuthenticationModule,
    ZoneModule
  ],
  declarations: [
    PartnerRetrievalComponent,
    PartnerUpdateComponent,
    PartnerManagementComponent
  ],
  entryComponents: [
    PartnerUpdateComponent
  ]
})
export class PartnerModule { }
