import { PictureConfig } from './picture-config';

/** Configuration part of an export task */
export class ExportTaskConfig {

  public static availableSchedulings = [
    'MONTHLY', 'WEEKLY_MONDAY', 'WEEKLY_TUESDAY', 'WEEKLY_WEDNESDAY', 'WEEKLY_THURSDAY',
    'WEEKLY_FRIDAY', 'WEEKLY_SATURDAY', 'WEEKLY_SUNDAY'
  ];
  public static onceScheduling = 'ONCE';

  constructor(public active: boolean,
    public scheduling: string,
    public diffExport: boolean,
    public pictureConfig: PictureConfig,
    public contactEmailAddress: string) { }
}
