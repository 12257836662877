import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { UserProfile } from '../../common';

/** A component used to create, display, edit and delete user accounts */
@Component({
  selector: 'app-user-management',
  templateUrl: './management.component.html'
})
export class ManagementComponent implements OnInit {

  userProfiles = UserProfile;

  constructor() { }

  ngOnInit() { }

}
