<div class="gallery-card">
  <p fxLayout="row" fxLayoutAlign="start stretch">
    <ng-container *ngIf="chooserMode; else simpleMode">
      <span fxLayout="row" fxLayoutAlign="start center">
        <button *ngIf="!selected" mat-icon-button color="primary" i18n-aria-label aria-label="Select visual" i18n-title title="Select visual" (click)="notifySelection()">
          <mat-icon>radio_button_unchecked</mat-icon>
        </button>
        <button *ngIf="selected" mat-icon-button color="primary" i18n-aria-label aria-label="Visual selected" i18n-title title="Visual selected">
          <mat-icon>radio_button_checked</mat-icon>
        </button>
      </span>
      <span fxLayout="column" fxLayoutAlign="start start">
        <span class="picture-wrapper" [class.border-default]="!selected" [class.border-primary]="selected" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="0">
          <app-picture-image [uuid]="picture.uuid" height="7rem" [clickable]="true" (onClicked)="notifySelection()"></app-picture-image>
        </span>
        <small fxFlex class="picture-caption clickable" [class.background-default]="!selected" [class.background-primary]="selected" (click)="notifySelection()">{{picture.tags}}</small>
      </span>
    </ng-container>
    <ng-template #simpleMode>
      <span fxLayout="column" fxLayoutAlign="start start">
        <span class="picture-wrapper border-default" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="0">
          <app-picture-image [uuid]="picture.uuid" height="7rem" [clickable]="false"></app-picture-image>
        </span>
        <small fxFlex class="picture-caption background-default">{{picture.tags}}<br>
          <span>{{picture.creationDate | date:'shortDate'}}</span>
        </small>
      </span>
      <span fxLayout="row" fxLayoutAlign="start center">
        <button *appAdminConnected mat-icon-button color="warn" i18n-aria-label aria-label="Delete visual" i18n-title title="Delete visual" (click)="openDeletionDialog()">
          <mat-icon>cancel</mat-icon>
        </button>
      </span>
    </ng-template>
  </p>
</div>
