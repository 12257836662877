<form [formGroup]="cmpformGroup">

  <mat-tab-group>
    <mat-tab i18n-label label="Basics">
      <div class="mg-top">
        <mat-form-field>
          <mat-label i18n>Task name</mat-label>
          <input matInput formControlName="name" required/>
          <mat-error *ngIf="cmpformGroup.controls.name.errors" i18n>Name is required
            and must contain less than 256 characters
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.625rem">
        <app-partner-chooser fxFlex="50" [partnerId]="cmpformGroup.controls.partnerId"></app-partner-chooser>
        <mat-form-field fxFlex>
          <mat-label i18n>Email address of the contact</mat-label>
          <input matInput formControlName="contactEmailAddress"/>
          <mat-error *ngIf="cmpformGroup.controls.contactEmailAddress.errors" i18n>Must be a valid email
            address and contain less than 128 characters
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="!executedOnce">
        <mat-label i18n>Scheduling</mat-label>
        <mat-select formControlName="scheduling" required>
          <mat-option *ngFor="let item of availableSchedulings" [value]="item">
            <span [ngSwitch]="item">
              <span i18n *ngSwitchCase="'MONTHLY'">Monthly</span>
              <span i18n *ngSwitchCase="'WEEKLY_MONDAY'">Every monday</span>
              <span i18n *ngSwitchCase="'WEEKLY_TUESDAY'">Every tuesday</span>
              <span i18n *ngSwitchCase="'WEEKLY_WEDNESDAY'">Every wednesday</span>
              <span i18n *ngSwitchCase="'WEEKLY_THURSDAY'">Every thursday</span>
              <span i18n *ngSwitchCase="'WEEKLY_FRIDAY'">Every friday</span>
              <span i18n *ngSwitchCase="'WEEKLY_SATURDAY'">Every saturday</span>
              <span i18n *ngSwitchCase="'WEEKLY_SUNDAY'">Every sunday</span>
            </span>
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="cmpformGroup.controls.scheduling.value === availableSchedulings[0]" i18n>The task is executed
          on the first day of each month
        </mat-hint>
      </mat-form-field>

      <div class="mg-top mg-bottom">
        <mat-radio-group formControlName="pictureType">
          <mat-radio-button [value]="availablePictureTypes[0]" i18n>Export only the main visuals</mat-radio-button>
          <mat-radio-button [value]="availablePictureTypes[1]">Export all visuals</mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.625rem">
        <app-picturesize-chooser fxFlex="50"
                                 [pictureSize]="cmpformGroup.controls.pictureSize"></app-picturesize-chooser>

        <mat-form-field fxFlex>
          <mat-label i18n>Target visuals format</mat-label>
          <mat-select formControlName="pictureFormat" required>
            <mat-option *ngFor="let item of availablePictureFormats" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </mat-tab>
    <mat-tab i18n-label label="Car models">

      <app-zone-multichooser [zones]="cmpformGroup.controls.zones" [includeNull]="true"></app-zone-multichooser>
      <app-carmaker-chooser [carMaker]="cmpformGroup.controls.carMaker" [includeNull]="true"></app-carmaker-chooser>
      <app-acriss-chooser [vehicleClasses]="cmpformGroup.controls.vehicleClasses"
                          [acrissCode]="cmpformGroup.controls.acrissCode"
                          [isVehicleClasses]="cmpformGroup.controls.isVehicleClasses"></app-acriss-chooser>

      <mat-form-field>
        <mat-label i18n>Ranks</mat-label>
        <mat-select multiple formControlName="modelRanks">
          <mat-option i18n>&lt;All ranks&gt;</mat-option>
          <mat-option *ngFor="let item of availableRanks" [value]="item">
            {{item}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-tab>
    <mat-tab i18n-label label="Advanced">

      <div class="mg-top" *ngIf="!executedOnce">
        <mat-slide-toggle formControlName="diffExport" i18n>
          Export only car models updated since the last execution of this task
        </mat-slide-toggle>
      </div>

      <mat-form-field>
        <mat-label i18n>Car models created on</mat-label>
        <input [matDatepicker]="sheetsCreatedOnDatepicker" matInput formControlName="createdOn"/>
        <mat-datepicker-toggle matSuffix [for]="sheetsCreatedOnDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #sheetsCreatedOnDatepicker></mat-datepicker>
        <mat-error *ngIf="cmpformGroup.controls.createdOn.errors" i18n>Date format is invalid.</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!cmpformGroup.controls.diffExport.value">
        <mat-label i18n>Car models last modified on</mat-label>
        <input [matDatepicker]="sheetsLastModifiedOnDatepicker" matInput formControlName="lastModifiedOn"/>
        <mat-datepicker-toggle matSuffix [for]="sheetsLastModifiedOnDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #sheetsLastModifiedOnDatepicker></mat-datepicker>
        <mat-error *ngIf="cmpformGroup.controls.lastModifiedOn.errors" i18n>Date format is invalid.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Car models created after</mat-label>
        <input [matDatepicker]="sheetsCreatedAfterDatepicker" matInput formControlName="createdAfter"/>
        <mat-datepicker-toggle matSuffix [for]="sheetsCreatedAfterDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #sheetsCreatedAfterDatepicker></mat-datepicker>
        <mat-error *ngIf="cmpformGroup.controls.createdAfter.errors" i18n>Date format is invalid.</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!cmpformGroup.controls.diffExport.value">
        <mat-label i18n>Car models last modified after</mat-label>
        <input [matDatepicker]="sheetsLastModifiedAfterDatepicker" matInput formControlName="lastModifiedAfter"/>
        <mat-datepicker-toggle matSuffix [for]="sheetsLastModifiedAfterDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #sheetsLastModifiedAfterDatepicker></mat-datepicker>
        <mat-error *ngIf="cmpformGroup.controls.lastModifiedAfter.errors" i18n>Date format is invalid.</mat-error>
      </mat-form-field>
    </mat-tab>
  </mat-tab-group>

</form>
