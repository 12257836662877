import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { Acriss } from '../acriss';

@Component({
  selector: 'app-acriss-chooser',
  templateUrl: './chooser.component.html'
})
export class ChooserComponent implements OnInit {

  availableVehicleClasses = Acriss.availableVehicleClasses;

  @Input('vehicleClasses')
  public vehicleClasses: FormControl;
  @Input('acrissCode')
  public acrissCode: FormControl;
  @Input('isVehicleClasses')
  public isVehicleClasses: FormControl;

  constructor() { }

  ngOnInit() { }
}
