import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppUser } from '../../common';

@Injectable({
  providedIn: 'root'
})
export class UserCreationService {

  constructor(private http: HttpClient) { }

  /**
   * @param user Data for the user to be created
   * @return An observable of the response
   */
  create(user: AppUser) {
    return this.http.post<any>('/api/admin/users', user);
  }
}
