import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';

/** Provides the authentication token header to any HTTP request */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let enrichedRequest = request;
    const authenticationToken = this.authenticationService.tokenValue;
    if (authenticationToken.connected) {
      enrichedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authenticationToken.token}`
        }
      });
    }
    return next.handle(enrichedRequest);
  }
}
