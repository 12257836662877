import { Component, OnInit } from '@angular/core';

/** A component used to create, display, edit and delete export tasks */
@Component({
  selector: 'app-exporttask-management',
  templateUrl: './management.component.html'
})
export class ManagementComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit() {

  }

}
