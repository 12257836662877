<div id="login-box" class="open-page" fxLayout="row" fxLayoutAlign="center center">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-card>
      <mat-card-header>
        <mat-card-title i18n>Welcome to Partner Car Visuals</mat-card-title>
        <mat-card-subtitle i18n>Access all Europcar Mobility Group car visuals displayed worldwide in all Europcar Mobility Group websites, partner sites and microsites.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <h3><span i18n>Need an account?</span>&nbsp;<a routerLink="/user/self/registration" mat-button color="accent" i18n>Sign up</a><br />
          <span i18n>Please, sign in.</span></h3>
        <mat-form-field>
          <mat-label i18n>Username</mat-label>
          <input matInput formControlName="username" required />
          <mat-error *ngIf="f.username.errors && f.username.errors.required" i18n>Username is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>Password</mat-label>
          <input matInput formControlName="password" required type="password" />
          <mat-error *ngIf="f.password.errors && f.password.errors.required" i18n>Password is required</mat-error>
        </mat-form-field>
        <p *ngIf="error" i18n class="warn">Authentication failure : wrong username or password.</p>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
          <button mat-raised-button color="primary" [disabled]="cannotSubmit" i18n>Sign in</button>
          <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
          <a routerLink="/user/self/newpassword" mat-button color="primary" i18n>New password</a>
        </div>
      </mat-card-actions>
      <mat-card-footer>
        <span i18n>If you encounter any technical problem:</span><a
        href="mailto:eci_q2c_set-up@europcar.com?subject=PCV Access" i18n mat-button>contact us</a>
      </mat-card-footer>
    </mat-card>
  </form>
</div>
