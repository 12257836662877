import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ActionState } from "../../common";
import { Picture } from "../common";
import { PictureGalleryService } from "./picturegallery.service";

/** A component used to display the gallery and select a picture in it */
@Component({
  selector: "app-picture-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
})
export class PictureGalleryComponent implements OnInit {
  /** The currently selected picture UUID */
  @Input("uuid")
  public uuid: string;
  /** Whether the chooser mode should be actived */
  @Input("chooserMode")
  public chooserMode: boolean;

  @Output()
  onSelected = new EventEmitter();
  cmpformGroup: FormGroup;

  pageSize: number = 50;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalLength: number;

  /** Items to be displayed */
  items$: Observable<Picture[]>;

  deletionActionState: ActionState = new ActionState();

  constructor(
    private formBuilder: FormBuilder,
    private pictureGalleryService: PictureGalleryService
  ) {}

  ngOnInit() {
    this.cmpformGroup = this.formBuilder.group({
      tags: [""],
    });
    this.onSubmit();
  }

  /** Executed when the search form is submitted */
  onSubmit() {
    this.items$ = this.pictureGalleryService
      .retrieveAll(this.formData(), this.pageSize, this.pageIndex)
      .pipe(
        tap((page) => (this.totalLength = page.totalElements)),
        map((page) => page.content)
      );
  }

  /** @return All data stored in the form */
  private formData() {
    return this.cmpformGroup.controls.tags.value;
  }

  /** Notifies that a picture was selected
   * @param picture Data on the selected picture */
  public notifySelection(picture: Picture) {
    this.uuid = picture ? picture.uuid : null;
    this.onSelected.emit(this.uuid);
  }

  /** Notifies that a picture was deleted
   * @param picture Data on the deleted picture */
  public notifyDeletion(picture: Picture) {
    if (picture.uuid === this.uuid) {
      this.uuid = null;
      this.onSelected.emit(this.uuid);
    }
    this.onSubmit();
  }

  /** Called when pagination data changes */
  onPaginationChange($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.onSubmit();
  }
}
