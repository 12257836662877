import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CarModel } from '../../common';

@Injectable({
  providedIn: 'root'
})
export class CarModelUpdateService {

  constructor(private http: HttpClient) { }

  /**
   * @param carModel Data for the car model to be updated
   * @return An observable of the response
   */
  update(carModel: CarModel) {
    return this.http.put<any>('/api/admin/carmodels', carModel);
  }
}
