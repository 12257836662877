import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';

import { AppUser } from '../../common';
import { Page } from '../../../common';
import { UserRetrievalRequest } from './user-retrieval-request';

@Injectable({
  providedIn: 'root'
})
export class UserRetrievalService {

  constructor(private http: HttpClient) { }

  /**
   * @param request Criteria for the request
   * @param pageSize Size of the page of users to be retrieved
   * @param pageNumber Index of the page to be retrieved, starting from 0
   * @return An observable of the response
   */
  retrieveAll(request: UserRetrievalRequest, pageSize: number = 50, pageNumber: number = 0) {
    return this.http.post<any>(`/api/admin/users/all?size=${pageSize}&page=${pageNumber}`, request)
    .pipe(share());
  }
}
