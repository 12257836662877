<div>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label i18n>Periodic export tasks</ng-template>
      <ng-template matTabContent>
        <app-exporttask-retrieval [executedOnce]="false"></app-exporttask-retrieval>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label i18n>One-time export tasks</ng-template>
      <ng-template matTabContent>
        <app-exporttask-retrieval [executedOnce]="true"></app-exporttask-retrieval>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
