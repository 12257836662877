import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ExportTask } from '../../common';

@Injectable({ providedIn: 'root' })
export class ExportTaskUpdateService {

  constructor(private http: HttpClient) { }

  /**
   * @param exportTask Data for the export task to be updated
   * @return An observable of the response
   */
  update(exportTask: ExportTask) {
    return this.http.put<any>('/api/admin/exporttasks', exportTask);
  }
}
