import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ZoneModule } from '../zone/zone.module';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './self/login';
import { UserRegistrationComponent } from './self/registration';

import { NewPasswordComponent } from './self/newpassword';
import { PasswordComponent } from './self/password';

import { UserCreationComponent } from './admin/creation';
import { UserRetrievalComponent } from './admin/retrieval';
import { UserUpdateComponent } from './admin/update';
import { UserDeletionComponent } from './admin/deletion';
import { UserManagementComponent } from './admin/management';

@NgModule({
  imports: [
    CoreModule,
    RouterModule,
    AuthenticationModule,
    ZoneModule
  ],
  declarations: [
    LoginComponent,
    UserRegistrationComponent,
    NewPasswordComponent,
    PasswordComponent,
    UserRetrievalComponent,
    UserCreationComponent,
    UserUpdateComponent,
    UserDeletionComponent,
    UserManagementComponent
  ],
  entryComponents: [
    UserCreationComponent,
    UserUpdateComponent,
    UserDeletionComponent
  ]
})
export class UserModule { }
