import { NgModule } from '@angular/core';

import { CoreModule } from '../core.module';
import { CommonModule } from '../common/common.module';
import { CarModelSearchModule } from '../carmodel-search/carmodel-search.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ZoneModule } from '../zone/zone.module';

import { ExportTaskRetrievalComponent } from './admin/retrieval';
import { ExportTaskManagementComponent } from './admin/management';
import { ExportTaskEditionComponent } from './admin/edition';
import { ExportTaskCreationComponent } from './admin/creation';
import { ExportTaskUpdateComponent } from './admin/update';
import { ExportTaskDeletionComponent } from './admin/deletion';
import { ExportTaskActivationComponent } from './admin/activation';
import { PictureSizeChooserComponent } from './picturesize/chooser';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    CarModelSearchModule,
    AuthenticationModule,
    ZoneModule
  ],
  declarations: [
    ExportTaskRetrievalComponent,
    ExportTaskManagementComponent,
    ExportTaskEditionComponent,
    ExportTaskCreationComponent,
    ExportTaskUpdateComponent,
    ExportTaskDeletionComponent,
    ExportTaskActivationComponent,
    PictureSizeChooserComponent
  ],
  entryComponents: [
    ExportTaskCreationComponent,
    ExportTaskUpdateComponent,
    ExportTaskDeletionComponent,
    ExportTaskActivationComponent
  ]
})
export class ExportTaskModule { }
