<div *appUserConnected>
  <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxGap="3rem" fxLayoutAlign="start center">
      <h2 class="mat-h2 clickable" routerLink="/" fxShow="true" fxHide.lt-sm="true">Partner Car Visuals</h2>
      &nbsp;
      <div fxLayout="row" fxGap="3rem" fxLayoutAlign="stretch start">
        <a mat-button routerLink="" i18n>Car models</a>
        <a *appAdminConnected mat-button routerLink="/picture/management/retrieval" i18n>Gallery</a>
        <a *appInternalConnected mat-button routerLink="/picture/management/retrieval" i18n>Gallery</a>
        <a *appAdminConnected mat-button routerLink="/exporttask/admin/management" i18n>Export tasks</a>
        <a *appAdminConnected mat-button routerLink="/user/admin/management" i18n>Users</a>
        <a *appAdminConnected mat-button routerLink="/partner/admin/management" i18n>Partners</a>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="stretch end">
      <!-- The following menu items will be hidden on both SM and XS screen sizes -->
      <a mat-button (click)="logout()" i18n>Sign out</a>
    </div>
  </mat-toolbar>
</div>
