import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CarModelPictureLinkUpdateService {

  constructor(private http: HttpClient) { }

  /**
   * @param carModelId ID of the car model whose picture is to be cleared
   * @param pictureType Type of the picture to be cleared
   * @param value The new value for the picture link
   * @return An observable of the response
   */
  updatePictureLink(carModelId: number, pictureType: string, value: string) {
    return this.http.put<any>(`/api/connected/carmodel/${carModelId}/relatedpictures/${pictureType}`, { value });
  }
}
