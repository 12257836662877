import { Directive, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication.service';

/** A directive that hides an HTML element if the current user is not connected and is not an administrator one */
@Directive({
  selector: '[appInternalConnected]'
})
export class InternalConnectedDirective {

  private hasView: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthenticationService) {
    authenticationService.token.subscribe(token => { this.render(token && token.connected && token.isInternal); }, error => { this.render(false); });
  }

  /** Renders the element
   * @param condition The condition enabling the rendering
   */
  private render(condition: boolean) {
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

}
