import { Zone } from '../zone/zone';
import { CarMaker } from '../common/carmaker/carmaker';
import { Acriss } from './acriss';

/** Criteria for the retrieval of car models */
export class CarModelSearchRequest {

  public static availableRanks = [0, 1, 2, 3];

  constructor(
    public id: number,
    public version: number,
    public modelId: string,
    public createdAfter: Date,
    public lastModifiedAfter: Date,
    public createdOn: Date,
    public lastModifiedOn: Date,
    public carMaker: CarMaker,
    public zones: Array<Zone>,
    public acrisses: Array<string>,
    public modelRanks: Array<number>,
    public isWithoutMainPicture: boolean,
    public isIncludeOnlyAvailable: boolean
  ) { }

  static from(obj: any) {
    return new CarModelSearchRequest(obj.id, obj.version, obj.modelId, obj.createdAfter,
      obj.lastModifiedAfter, obj.createdOn, obj.lastModifiedOn, obj.carMaker, obj.zones, obj.acrisses, obj.modelRanks, obj.isWithoutMainPicture, obj.isIncludeOnlyAvailable);
  }

  /** @return Whether the acrisses array corresponds to car classes */
  isVehicleClasses() {
    return this.acrisses && this.acrisses.length > 0 && (Acriss.availableVehicleClasses.some(item => item.value === this.acrisses[0]) || this.acrisses.length > 1);
  }

  /** @return The car classes corresponding to the acriss codes */
  vehicleClasses() {
    return this.isVehicleClasses() ? this.acrisses : [];
  }

  /** @return The ACRISS code corresponding to the acriss codes array */
  acrissCode() {
    return !this.isVehicleClasses() && this.acrisses && this.acrisses[0] || null;
  }

  /** @param value An array of values, can be non defined
   * @return An empty array if input is non defined or the input array */
  static normalizeMultiSelectValue(value: Array<any>) {
    let result = [];
    if (value) {
      result = value.filter(item => item === null || item === undefined).length > 0 ? [] : value;
    }
    return result;
  }

  /** Computes acrisses from the passed values
   * @param isVehicleClasses Whether acriss codes should be ignored
   * @param vehicleClasses Value for the vehicle classes
   * @param acrissCode Value for the acriss code
   */
  static computeAcrisses(isVehicleClasses: boolean, vehicleClasses: any, acrissCode: any) {
    return this.normalizeMultiSelectValue(isVehicleClasses ? vehicleClasses : [acrissCode || null]);
  }
}
