import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { PictureConfigService } from '../config/pictureconfig.service';

/** A component used to display the image file corresponding to a picture */
@Component({
  selector: 'app-picture-image',
  templateUrl: './pictureimage.component.html',
  styleUrls: ['./pictureimage.component.scss']
})
export class PictureImageComponent implements OnInit {

  /** The UUID of the picture to be displayed */
  @Input('uuid')
  public uuid: string;

  /** The width of the image to be displayed, optional */
  @Input('width')
  public width: string = 'auto';

  /** The height of the image to be displayed, optional */
  @Input('height')
  public height: string = 'auto';

  /** Whether the image can be clicked */
  @Input('clickable')
  public clickable: boolean = false;

  @Output()
  onClicked = new EventEmitter();

  constructor(private pictureConfigService: PictureConfigService) { }

  ngOnInit() {
    this.pictureConfigService.retrieve();
  }

  /** @return The UUID of the thumbnail corresponding to the uuid */
  get thumbnailUuid() {
    return this.uuid ? ('thumbnail_' + this.uuid.replace('.png', '') + '.jpg') : null;
  }

  /** Notifies that the image was clicked */
  public notifyClick() {
    this.onClicked.emit(this.uuid);
  }

}
