import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {CarModel} from '../../../common';
import {CarModelUpdateComponent} from '../../update';
import {CarModelDeletionComponent} from '../../deletion';

/** A component used to display data on a car model */
@Component({
  selector: 'app-carmodel-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CarModelCardComponent implements OnInit {

  @Input('carModel')
  public carModel: CarModel;
  @Output() onRefreshRequired = new EventEmitter();

  public flipped: boolean = false;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  /** Requires that a refresh on data be called */
  private requireRefresh() {
    this.onRefreshRequired.emit('refresh');
  }

  /** Opens the update dialog for this car model */
  openUpdateDialog(): void {
    const dialogRef = this.dialog.open(CarModelUpdateComponent, {
      width: '33rem',
      data: this.carModel
    });
    dialogRef.afterClosed().subscribe(result => {
      this.requireRefresh();
    }, error => {
      this.requireRefresh();
    });
  }

  /** Opens the deletion dialog for this car model */
  openDeletionDialog(): void {
    const dialogRef = this.dialog.open(CarModelDeletionComponent, {
      width: '25rem',
      data: this.carModel
    });
    dialogRef.afterClosed().subscribe(result => {
      this.requireRefresh();
    }, error => {
      this.requireRefresh();
    });
  }

}
