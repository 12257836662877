import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ExportTask, ExportTaskConfig, PictureConfig, Reference} from '../../common';
import {CarModelSearchRequest} from '../../../carmodel-search/carmodel-search-request';
import {ExportTaskUpdateService} from './exporttask-update.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-exporttask-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  availableSchedulings = ExportTaskConfig.availableSchedulings;
  availablePictureFormats = PictureConfig.availablePictureFormats;
  availablePictureTypes = PictureConfig.availablePictureTypes;
  availableRanks = CarModelSearchRequest.availableRanks;

  cmpformGroup: FormGroup;
  actionState: ActionState = new ActionState();
  executedOnce: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public original: ExportTask,
    private formBuilder: FormBuilder,
    private exportTaskUpdateService: ExportTaskUpdateService
  ) {
  }

  ngOnInit() {
    const required256 = Validators.compose([Validators.required, Validators.maxLength(256)]);
    const optional5 = Validators.maxLength(5);
    const carModelSearchRequest = CarModelSearchRequest.from(this.original.carModelSearchRequest);
    this.cmpformGroup = this.formBuilder.group({
      name: [this.original.name, required256],
      partnerId: [this.original.partner.id, Validators.required],

      scheduling: [this.original.config.scheduling, Validators.required],
      diffExport: [this.original.config.diffExport],
      contactEmailAddress: [this.original.config.contactEmailAddress, Validators.compose([Validators.email, Validators.maxLength(128)])],

      pictureSize: [this.original.config.pictureConfig.pictureSize, Validators.required],
      pictureFormat: [this.original.config.pictureConfig.pictureFormat, Validators.required],
      pictureType: [this.original.config.pictureConfig.pictureType, Validators.required],

      zones: [carModelSearchRequest.zones],
      carMaker: [carModelSearchRequest.carMaker],
      vehicleClasses: [carModelSearchRequest.vehicleClasses()],
      acrissCode: [carModelSearchRequest.acrissCode(), optional5],
      isVehicleClasses: [carModelSearchRequest.isVehicleClasses()],
      modelRanks: [carModelSearchRequest.modelRanks],
      createdOn: [carModelSearchRequest.createdOn],
      lastModifiedOn: [carModelSearchRequest.lastModifiedOn],
      createdAfter: [carModelSearchRequest.createdAfter],
      lastModifiedAfter: [carModelSearchRequest.lastModifiedAfter]
    });
  }

  /** @return All data stored in the form */
  private formData() {
    const f = this.cmpformGroup.controls;
    const partner = new Reference(f.partnerId.value);
    const pictureConfig = new PictureConfig(f.pictureSize.value, f.pictureFormat.value, f.pictureType.value);
    const config = new ExportTaskConfig(true, f.scheduling.value, f.diffExport.value, pictureConfig, f.contactEmailAddress.value);
    const carModelSearchRequest = new CarModelSearchRequest(this.original.carModelSearchRequest.id,
      this.original.carModelSearchRequest.version, null,
      f.createdAfter.value, f.lastModifiedAfter.value, f.createdOn.value,
      f.lastModifiedOn.value, f.carMaker.value, CarModelSearchRequest.normalizeMultiSelectValue(f.zones.value), CarModelSearchRequest.computeAcrisses(f.isVehicleClasses.value, f.vehicleClasses.value, f.acrissCode.value),
      CarModelSearchRequest.normalizeMultiSelectValue(f.modelRanks.value), false, false);
    return new ExportTask(this.original.id, this.original.version, f.name.value, partner, config, carModelSearchRequest, null);
  }

  /** Executed when the user confirms the action */
  onSubmit() {
    this.actionState.register(this.exportTaskUpdateService.update(this.formData()));
  }

}
