import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService, Credentials } from '../../../authentication';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  error = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
  }

  /** Checks whether an authentication is required and redirects to default page, if user is already authenticated */
  private checkIfAuthenticationRequired() {
    const token = this.authenticationService.tokenValue;
    if (token && token.connected) {
      this.router.navigate(['/']);
    }
  }

  /** Retrieves the current URL and stores it */
  private storeReturnUrl() {
    this.returnUrl = (this.route.snapshot && this.route.snapshot.queryParams ?
      this.route.snapshot.queryParams.returnUrl : undefined) || '/';
  }
  /** Subscribes to any update of the authentication token */
  private subscribeToTokenUpdates() {
    this.authenticationService.token.subscribe(
      token => {
        this.error = false;
        if (token) {
          if (token.connected) {
            this.router.navigate([this.returnUrl]);
            this.error = false;
          } else if (token.error) {
            this.error = true;
          }
        }
        this.loading = false;
      });
  }

  ngOnInit() {
    this.subscribeToTokenUpdates();
    this.checkIfAuthenticationRequired();
    this.storeReturnUrl();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  /** Convenience getter for easy access to form fields */
  get f() { return this.loginForm.controls; }

  /** @return All data stored in the form */
  formData() { return new Credentials(this.f.username.value, this.f.password.value); }

  /** Whether the form can be submitted */
  get cannotSubmit() { return this.loading || this.loginForm.invalid; }

  /** On form submission */
  onSubmit() {
    this.error = false;
    this.loading = true;
    this.authenticationService.login(this.formData());
  }
}
