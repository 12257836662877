/** Data related to ACRISS codes */
export class Acriss {

  public static availableVehicleClasses = [
    { label: 'Mini', value: 'M' },
    { label: 'Mini Elite', value: 'N' },
    { label: 'Economy', value: 'E' },
    { label: 'Economy Elite', value: 'H' },
    { label: 'Compact', value: 'C' },
    { label: 'Compact Elite', value: 'D' },
    { label: 'Intermediate', value: 'I' },
    { label: 'Intermediate Elite', value: 'J' },
    { label: 'Standard', value: 'S' },
    { label: 'Standard Elite', value: 'R' },
    { label: 'Fullsize', value: 'F' },
    { label: 'Fullsize Elite', value: 'G' },
    { label: 'Premium', value: 'P' },
    { label: 'Premium Elite', value: 'U' },
    { label: 'Luxury', value: 'L' },
    { label: 'Luxury Elite', value: 'W' },
    { label: 'Oversize', value: 'O' },
    { label: 'Special', value: 'X' }
  ];
}
