import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserRegistration } from './user-registration';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {

  constructor(private http: HttpClient) { }


  /** Notifies a user registration
   * @param userRegistration Data on the user making the registration.
   * @return The response
   */
  notify(userRegistration: UserRegistration) {
    return this.http.post<any>('/api/open/users/registration', userRegistration);
  }
}
