import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ExportTask} from '../../common';
import {ExportTaskDeletionService} from './exporttask-deletion.service';
import {ActionState} from '../../../common';

@Component({
  selector: 'app-exporttask-deletion',
  templateUrl: './deletion.component.html'
})
export class DeletionComponent implements OnInit {

  actionState: ActionState = new ActionState();

  constructor(
    @Inject(MAT_DIALOG_DATA) public exportTask: ExportTask,
    private exportTaskDeletionService: ExportTaskDeletionService
  ) { }

  ngOnInit() {
  }

  /** Executed when the user confirms the action */
  onAction() {
    this.actionState.register(this.exportTaskDeletionService.deleteExportTask(this.exportTask.id));
  }

}
