<h1 class mat-dialog-title i18n>Remove visual</h1>
<h4 *ngIf="actionState.success" class="success" i18n>Success</h4>
<h4 *ngIf="actionState.error" class="warn" i18n><strong>Error</strong></h4>
<div mat-dialog-content>
  <span *ngIf="actionState.initial" i18n>Do you want to remove the visual for this car model?</span>
  <span *ngIf="actionState.success" i18n>The visual was removed for the car model. The picture was not deleted.</span>
  <span *ngIf="actionState.error" class="warn" i18n>The visual could not be removed for the car model.</span>
</div>
<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
    <button *ngIf="actionState.initial" mat-button mat-dialog-close i18n>No</button>
    <button *ngIf="actionState.initial" mat-raised-button color="primary" (click)="onAction()" i18n>Yes</button>
    <mat-spinner *ngIf="actionState.loading" diameter="20"></mat-spinner>
    <button *ngIf="!actionState.initial" mat-button [mat-dialog-close]="actionState.success" i18n>Back to car models</button>
  </div>
</mat-dialog-actions>
