import {CarMaker} from '../../common/carmaker/carmaker';

/** A car model description */
export class CarModelDescription {
  constructor(public label: string,
              public alternativeLabel: string,
              public acriss: string,
              public carMaker: CarMaker,
              public carMakerName: string,
              public category: string,
              public seats: number,
              public doors: number,
              public bootCapacity: string,
              public modelRank: number) { }
}
