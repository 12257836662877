import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ActionState} from '../../../common';
import {UserRegistration} from './user-registration';
import {UserRegistrationService} from './user-registration.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {

  formGroup1: FormGroup;
  formGroup2: FormGroup;
  formGroup3: FormGroup;
  actionState: ActionState = new ActionState();
  /** Boolean indicating if the user accepts to register his personal data */
  gdprAccepted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userRegistrationService: UserRegistrationService,
  ) {
  }

  ngOnInit() {
    const required256 = Validators.compose([Validators.required, Validators.maxLength(256)]);
    const optional256 = Validators.maxLength(256);
    this.formGroup1 = this.formBuilder.group({
      lastName: ['', required256],
      firstName: ['', required256],
      emailAddress: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(128)])],
      phoneNumber: ['', Validators.maxLength(20)],
    });
    this.formGroup2 = this.formBuilder.group({
      company: ['', required256],
      department: ['', required256],
      position: ['', optional256],
    });
    this.formGroup3 = this.formBuilder.group({
      address1: ['', optional256],
      address2: ['', optional256],
      postalCode: ['', Validators.maxLength(10)],
      city: ['', optional256],
      country: ['', required256],
    });
  }

  /** @return All data stored in the form */
  formData() {
    const f1 = this.formGroup1.controls;
    const f2 = this.formGroup2.controls;
    const f3 = this.formGroup3.controls;
    return new UserRegistration(
      f1.lastName.value,
      f1.firstName.value,
      f1.emailAddress.value,
      f1.phoneNumber.value,
      f2.company.value,
      f2.department.value,
      f2.position.value,
      f3.address1.value,
      f3.address2.value,
      f3.postalCode.value,
      f3.city.value,
      f3.country.value,
    );
  }

  /** On form submission */
  onSubmit() {
    this.actionState.register(this.userRegistrationService.notify(this.formData()));
  }
}
